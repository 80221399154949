.selected-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 360px;
    gap: 10px;
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 13px;
}

.others {
    width: 175px !important;
    max-width: 175px !important;
    height: 100px;
}

.embed-a {
    position: absolute;
    width: 100%;
    height: 100%;
}

.overlay {
    position: absolute;
    background-color: #100d26ae;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid var(--room-item);
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.overlay span {
    font-size: 32px;
}

.selected-items img {
    height: auto;
    width: calc(100% + 20px);
    object-fit: cover;
}

.fit-screen {
    width: 100%;
    overflow: hidden;
    height: 100%;
    position: relative;
}

.fit-screen>img {
    height: 100%;
    object-fit: cover;
}

.fit-screen>img,
.fit-screen>video,
.fit-screen>.others,
.fit-screen>.fileReader {
    width: 100%;
}

.mediaContainer {
    overflow: hidden;
    position: relative;
}

.mediaContainer>img,
.mediaContainer>video,
.mediaContainer .fileReader {
    width: 175px;
    height: 100px;
    display: inline-flex;
    overflow: hidden;
    object-fit: cover;
    border-radius: 12px;
}

.others {
    max-width: unset;
    text-align: left;
    object-fit: cover;
    border-radius: 12px;
    font-weight: 600;
}

.others p {
    font-size: 12px;
}

.others>p {
    font-weight: 400;
    font-size: 11px;
    word-break: break-all;
    user-select: none;
    margin-top: 4px;
}

.mediaContainer .others {
    width: 175px;
    height: 100px;
}

.remove-icon {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 16px;
    height: 16px;
    opacity: 0;
    z-index: 333;
    border-radius: 50%;
    background-color: var(--text-secondary);
    width: 25px;
    height: 25px;
    transition: all 0.3s ease;
}

.remove-icon img {
    width: 16px;
    height: 16px;
}

.mediaContainer:hover .remove-icon,
.fit-screen:hover .remove-icon {
    right: 4px;
    top: 4px;
    opacity: 1;
}

.video-duration {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    width: fit-content;
    height: 16px;
    padding: 3px 6px;
    background-color: var(--white);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--toast-btn);
    font-size: 10px;
    font-weight: 500;
}

@media screen and (max-width: 480px) {
    .selected-items {
        max-width: 500px;
    }

    .mediaContainer .remove-icon,
    .fit-screen .remove-icon {
        right: 4px;
        top: 4px;
        opacity: 1;
    }
}