.react-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    background-color: var(--dark-blue-2);
    padding: 4px 8px;
    border-radius: 32px;
    margin-inline-start: auto;
    position: absolute;
    top: 10px;
    right: 10px;
}

.icon {
    transform: scale(1.3);
    transition: all 0.2s ease;
}

.open-emoji-board {
    transform: scale(1);
    transition: all 0.2s ease;
}

.icon:hover,
.open-emoji-board:hover {
    transform: scale(1.2);
}

.react-container aside {
    position: absolute !important;
    bottom: 10px !important;
    right: 0 !important;
}

.keyboard {
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 300px;
    height: 100%;
    transform: translateY(-30px);
}