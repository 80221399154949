.modal-container {
    position: fixed;
    width: 100vw;
    height: var(--height);
    left: 0;
    z-index: 33;
    bottom: 0;
    margin: 0;
    padding: 0;
}
