.wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 8px;
    position: relative;
    border-top-left-radius: 8px;
    height: 32px;
    box-sizing: content-box;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #2F2B4A;
}

.wrap h2 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 22px;
    color: var(--text-2);
    margin: 0;
    user-select: none;
}

.backward {
    margin-inline-end: auto;
}