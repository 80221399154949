.container {
    /* margin-bottom: 36px; */
    /* width: calc(100% - 26px); */
    background-color: var(--room-item);
    color: var(--gray-4);
    /* position: absolute; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-bottom: 1px solid var(--main-card-blue-head-opacity);
    border-radius: 8px 8px 0px 0px;
    outline: none;
    max-height: 200px;
    bottom: 50px;
    overflow-y: auto;
    z-index: 1;
    position: sticky;
    bottom: 100%;
    width: 100%;
}

.container::-webkit-scrollbar {
    display: none;
}

.container>ul {
    /* max-height: 300px; */
    /* margin-bottom: 20px; */
    /* padding: 16px 0px; */
    padding-bottom: 0;
    /* background-color: red; */
}

.container>ul>div {
    /* margin-bottom: 12px; */
    padding: 8px 16px;
    border-radius: 4px;
}

.d-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.icon>img {
    width: 28px;
    height: 28px;
    border-radius: 8px;
}

.icon {
    display: flex;
    align-self: center;
}

.d-flex p {
    margin: 0;
    font-size: 13px;
    padding: 0px;
}

.default {
    padding: 8px 16px;
}