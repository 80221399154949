[data-theme='LIGHT'] {
    --covchat-title: #33333314;
    --title-shadow: #e4e5f1;
    --tooltip: #7277ae;
    --white: #353a75;
    --keep-white: #ffffff;
    --white-20: #ffffff33;
    --white-50: #353a7580;
    --main-bg-blue: #ffff;
    --white-2: #f6f6f6;
    --white-3: #353a75;
    --msg-actions: #e4e5f1;
    --search-bar: #e4e5f1;
    --main-card-blue: #f6f6f6;
    --main-card-blue-head: #353a75;
    --main-card-blue-head-opacity: #7277ae;
    --right-sidebar: #e4e5f1;
    --left-sidebar: #353a75;
    --room-header: #353a75;
    --gray: #636175;
    --blur-blue: #100d2680;
    --red: #fb433a;
    --green: #29cd3a;
    --green-2: #2fcd55;
    --green-3: #11812c;
    --green-4: #2bb415;
    --purple: #5a567b;
    --purple-2: #9893c5;
    --hr: #dcdbe7;
    --room-item: #e4e5f1;
    --text-primary: #fff;
    --text-secondary: #5e5d72;
    --text-heading: #c5cee4;
    --text-2: #353a75;
    --text-3: #ffffff;
    --blue-primary: #009fff;
    --chat-background: #0c0922;
    --gray-5: #ffffff;
    --blue-1: #1175d3;
    --dark-1: #7277ae;
    --dark-blue-2: #252148;
    --dark-blue-3: #3e3a66;
    --gray-3: #7f7d89;
    --light-blue-1: #e4e5f1;
    --blue-metal: #616b85;
    --tag-bg-brown: #ffdb94;
    --tag-text: #ed8001;
    --tag-text-gray: #3e3d54;
    --bright-hr: #e4e5f1;
    --dashed-border: #807ca9;
    --navy-1: #7277ae;
    --status-offline: #f5315d;
    --status-resting: #ff6a00;
    --status-later: #ecf01b;
    --status-airplane: #009fff;
    --status-hide: #d0c9ff;
    --navy-2: #acafd4;
    --navy-3: #e4e5f1;
    --purple-light: #e3e4f2;
    --date-color: #5c577b;
    --toast-btn: #2e3547;
    --toast: #2e354780;
    --scrollbar-track: #e4e5f1;
    --drop-down-item: #7277ae;
    --drop-down-line: #dcdbe7;
    --dark-input: #e4e5f1;
    --dropdown-bg-hover: #7277ae;
    --light-to-navy: #3d3865;
    --darkNavy-to-lightNavy: #7277ae;
    --tabs-header: #e4e5f1;
    --tabs-text-header: #ffffff;
    --notifications-line: #acafd4;
    --message-time: #7277ae;
    --reaction-on-hover: #7277ae;
    --table-header-color: #c5cee4;
    --blue-icon: #D2D0E3;
}

[data-theme='DARK'] {
    --covchat-title: #14112d;
    --title-shadow: #0c0922;
    --white: #ffffff;
    --tooltip: #ffffff;
    --keep-white: #ffffff;
    --white-20: #ffffff33;
    --white-50: #ffffff80;
    --main-bg-blue: #181436;
    --white-2: #f6f6f6;
    --white-3: #d1d0e1;
    --msg-actions: #d1d0e1;
    --room-header: #0c0922;
    --main-card-blue: #100d26;
    --main-card-blue-head: #171331;
    --main-card-blue-head-opacity: #2d284c;
    --right-sidebar: #0c0922;
    --left-sidebar: #07051b;
    --search-bar: #07051b;
    --blur-blue: #100d2680;
    --gray: #636175;
    --red: #fb433a;
    --red-signout: #f5315d;
    --green: #29cd3a;
    --green-2: #2fcd55;
    --green-3: #11812c;
    --green-4: #2bb415;
    --green-5: #2bb41520;
    --orange: #ffa800;
    --yellow: #ffd600;
    --purple: #5a567b;
    --purple-2: #9893c5;
    --hr: #1d1936;
    --room-item: #302c55;
    --room-item-opacity: rgba(47, 43, 84, 0.9);
    --text-primary: #fff;
    --text-secondary: #5e5d72;
    --text-heading: #586c9d;
    --text-2: #dcdbe7;
    --text-3: #6f7b9a;
    --text-4: #b8c8f0;
    --blue-primary: #009fff;
    --chat-background: #0c0922;
    --gray-5: #7c7896;
    --blue-1: #1175d3;
    --dark-1: #070513;
    --dark-blue-2: #252148;
    --dark-blue-3: #3e3a66;
    --gray-3: #7f7d89;
    --light-blue-1: #9a95bf;
    --blue-metal: #616b85;
    --bright-hr: #2f2b4a;
    --dashed-border: #807ca9;
    --status-offline: #f5315d;
    --status-resting: #ff6a00;
    --status-later: #ecf01b;
    --status-airplane: #009fff;
    --status-hide: #d0c9ff;
    --navy-1: #7277ae;
    --navy-2: #acafd4;
    --navy-3: #353a75;
    --purple-light: #e3e4f2;
    --tag-bg-brown: #906003;
    --tag-text: #ffa903;
    --tag-text-gray: #3e3d54;
    --emoji-bg: #505d7c;
    --date-color: #5c577b;
    --toast-btn: #2e3547;
    --toast: #2e354780;
    --scrollbar-track: #1d1936;
    --drop-down-item: #ffffff;
    --drop-down-line: #3e3a66;
    --dropdown-bg-hover: #3e3a66;
    --dark-input: #0c0922;
    --light-to-navy: #dcdbe7;
    --darkNavy-to-lightNavy: #302c55;
    --tabs-header: #302c55;
    --tabs-text-header: #302c55;
    --notifications-line: #37415b;
    --message-time: #6f7b9a;
    --reaction-on-hover: #181436;
    --table-header-color: #586c9d;
    --tabs-management-header: #1c183c;
    --blue-icon: #4B476B;

}

[data-theme='DARK-BLUE'] {
    --covchat-title: #14112d;
    --title-shadow: #0c0922;
    --white: #ffffff;
    --tooltip: #ffffff;
    --keep-white: #ffffff;
    --white-20: #ffffff33;
    --white-50: #ffffff80;
    --main-bg-blue: #0e132a;
    --white-2: #f6f6f6;
    --white-3: #d1d0e1;
    --msg-actions: #d1d0e1;
    --room-header: #090e22;
    --main-card-blue: #0d0f26;
    --main-card-blue-head: #171331;
    --main-card-blue-head-opacity: #2d284c;
    --right-sidebar: #090a22;
    --left-sidebar: #05081b;
    --search-bar: #07051b;
    --blur-blue: #100d2680;
    --gray: #636175;
    --red: #fb433a;
    --red-signout: #f5315d;
    --green: #29cd3a;
    --green-2: #2fcd55;
    --green-3: #11812c;
    --green-4: #2bb415;
    --purple: #5a567b;
    --purple-2: #9893c5;
    --hr: #191a36;
    --room-item: #2c3255;
    --room-item-opacity: rgba(44, 50, 85, 0.9);
    --text-primary: #fff;
    --text-secondary: #5e5d72;
    --text-heading: #58709d;
    --text-2: #dcdbe7;
    --text-3: #6f7b9a;
    --text-4: #b8c8f0;
    --blue-primary: #009fff;
    --chat-background: #090f22;
    --gray-5: #7c7896;
    --blue-1: #1175d3;
    --dark-1: #070513;
    --dark-blue-2: #212848;
    --dark-blue-3: #3e3a66;
    --gray-3: #7f7d89;
    --light-blue-1: #9a95bf;
    --blue-metal: #616b85;
    --bright-hr: #2b2d4a;
    --dashed-border: #807ca9;
    --status-offline: #f5315d;
    --status-resting: #ff6a00;
    --status-later: #ecf01b;
    --status-airplane: #009fff;
    --status-hide: #d0c9ff;
    --navy-1: #7277ae;
    --navy-2: #acafd4;
    --navy-3: #353a75;
    --purple-light: #e3e4f2;
    --tag-bg-brown: #906003;
    --tag-text: #ffa903;
    --tag-text-gray: #3e3d54;
    --emoji-bg: #505d7c;
    --date-color: #5c577b;
    --toast-btn: #2e3547;
    --toast: #2e354780;
    --scrollbar-track: #191c36;
    --drop-down-item: #ffffff;
    --drop-down-line: #3a4066;
    --dropdown-bg-hover: #3a4266;
    --dark-input: #070c1e;
    --light-to-navy: #dcdbe7;
    --darkNavy-to-lightNavy: #2c3155;
    --tabs-header: #302c55;
    --tabs-management-header: #1c183c;
    --tabs-text-header: #302c55;
    --notifications-line: #37415b;
    --message-time: #6f7b9a;
    --reaction-on-hover: #181436;
    --table-header-color: #586c9d;
}

[data-platform='desktop'] {
    --height: calc(100vh - 40px) !important;
}

[data-platform='browser'] {
    --height: 100dvh !important;
}

.App {
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    background-color: var(--main-bg-blue);
    color: var(--white);
    overflow: hidden;
    scroll-behavior: smooth;
    overflow-y: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.centerContainer {
    height: var(--height);
    flex-direction: column;
    display: flex;
}

.justifyCenter {
    justify-content: center;
}

.text-7xl {
    font-size: 4.5rem;
    line-height: 1;
}

.gradient-text {
    background: linear-gradient(92.22deg, #7277AE 29.76%, #ACAFD4 70.72%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.primaryHeading {
    color: var(--text-primary);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.secondaryHeading {
    color: var(--text-heading);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.subHeading {
    color: var(--text-secondary);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.marginVertical-small {
    margin: 8px 0;
}

::-webkit-scrollbar {
    width: 10px;
    /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
    border-radius: 8px;
    /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
    background-color: var(--main-card-blue);
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 2px solid var(--scrollbar-track);
    /* creates padding around scroll thumb */
}

input[type='checkbox'] {
    position: relative;
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    border: none;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    box-shadow: 0 0 0px 1000px transparent inset;
    color: var(--text-secondary) !important;
    -webkit-text-fill-color: var(--text-secondary) !important;
    transition: background-color 5000s ease-in-out 0s;
}

input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 1px solid var(--room-item);
    border-radius: 99px;
    background-color: var(--chat-background);
    transform: translate(-15%, -15%);
}

input[type='checkbox']:checked::before {
    background-color: var(--blue-primary) !important;
}

input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1.5px;
    left: 4px;
}

::-webkit-progress-bar {
    width: 100%;
    height: 12px;
    border-radius: 8px;
    border: 1px solid var(--room-item);
    background-color: var(--room-item);
    color: var(--blue-primary);
}

select {
    padding: 12px;
    border-radius: 8px;
    color: var(--text-secondary);
    background: var(--chat-background);
    border: none;
    outline: none;
}

/* uncaught runtime errors react disable */
iframe#webpack-dev-server-client-overlay {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.select:focus-visible {
    outline: 0;
    border: 0;
}

.select {
    width: 100%;
}

body {
    font-family: 'Inter', 'Poppins', sans-serif !important;
    font-weight: 400;
}

.select>div {
    background-color: var(--room-header);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid transparent;
    outline: 0;
}

.relative {
    position: relative;
}

.btn-link {
    display: flex;
    min-width: 258px;
    width: fit-content;
    padding: 12px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    background-color: var(--blue-primary);
    color: var(--text-primary);
}

.m-auto {
    margin: auto !important;
}

.items-center {
    align-items: center !important;
}

.text-dot {
    background-color: #6F7B9A;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: inline-block;
}

.font-10 {
    font-size: 10px;
}

.my-0 {
    margin-block: 0;
}

.m-0 {
    margin: 0;
}

.gap-3 {
    gap: 12px;
}

.mt-0 {
    margin-top: 0;
}

.mb-0 {
    margin-bottom: 0;
}

@media screen and (max-width: 480px) {
    .font-sm-7 {
        font-size: 7px !important;
    }

    b,
    span,
    label,
    p {
        font-size: 0.8rem;
    }

    h2 {
        font-size: 1.1rem;
    }

    .text-sm-center {
        text-align: center;
    }

    .secondaryHeading {
        font-size: 22px;
    }

    .swal2-icon {
        font-size: 12px;
    }

}