.headingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.appButton {
    margin: 16px 0;
}

.contentWrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
}

.vertical-bar-container {
    width: fit-content;
    min-width: 30px;
    height: var(--height);
    background-color: var(--left-sidebar);
    margin: 0;
    position: relative;
    overflow-y: hidden;
}

.vertical-bar-container ul {
    height: calc(var(--height) - 55px);
    overflow-y: scroll;
    margin: 0;
    width: 64px;
    padding-bottom: 32px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
}

.vertical-bar-container ul::-webkit-scrollbar {
    display: none;
}

.server-badge {
    width: 64px;
    height: 64px;
    object-fit: cover;
    margin-bottom: 16px;
    margin-block-end: 16px;
    cursor: pointer;
}

.vertical-bar-container li:last-of-type .server-badge {
    margin-bottom: 0;
    margin-block-end: 0;
}

.badge-img {
    width: 64px;
    height: 64px;
    object-fit: cover;
}

.horiz-cross,
.vertical-cross {
    background-color: var(--green-3);
    border-radius: 35px;
    position: absolute;
    inset: 0;
    margin: auto;
}

.vertical-cross {
    width: 3px;
    height: 24px;
}

.horiz-cross {
    height: 3px;
    width: 24px;
}

.relative {
    position: relative;
    background-color: var(--main-card-blue);
    border-radius: 24px;
}

.servers-list {
    padding: 16px;
}

.indicator {
    position: absolute;
    right: 0;
    top: -10px;
}

.p2p-wrap {
    position: relative;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--text-heading);
}

.logo {
    max-height: 25vh;
}

@media screen and (max-width: 480px) {
    .logo {
        max-height: 150px;
    }

    .contentWrapper {
        justify-content: space-evenly;
    }

    .p2p-wrap {
        margin: 0 auto;
    }
    .appButton { 
        min-width: unset;
        width: 100%;
    }
    .center-container { 
        width: calc(100% - 16px);
    }
}