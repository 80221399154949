.form-container {
    position: relative;
    padding: 12px;
    height: min-content;
    margin-top: 0;
    margin-bottom: 0;
}

.NewMessageInput {
    padding: 12px 80px 12px 46px;
    width: calc(100% - 80px - 46px);
    background-color: var(--room-item);
    color: var(--gray-4);
    position: relative;
    overflow-x: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 0;
    border-radius: 4px;
    outline: none;
    max-height: 100px;
    bottom: 0;
    word-break: break-word;
}

.animated-div {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.animated-div.visible {
    opacity: 1;
}

.NewMessageInput::-webkit-scrollbar {
    width: 0.5rem;
    /* Set the width to a small value */
    display: none;
    /* Hide the scrollbar */
}

.NewMessageInput:focus-visible,
.NewMessageInput:focus {
    outline: none;
    border: none;
}

.NewMessageInput:-webkit-autofill,
.NewMessageInput:-webkit-autofill:hover,
.NewMessageInput:-webkit-autofill:focus,
.NewMessageInput:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--gray-4);
    box-shadow: inset 0 0 20px 20px var(--room-item);
    transition: background-color 5000s ease-in-out 0s;
}

.btns-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 24px;
    /* top: 0; */
    bottom: 22px;
    margin: auto 0;
    width: min-content;
    height: min-content;
    column-gap: 12px;
}

.attachment {
    position: absolute;
    z-index: 2;
    left: 24px;
    /* top: 0; */
    bottom: 22px;
    margin: auto;
}

.content {
    gap: 5px;
    margin-top: 8px;
    position: relative;
}

.replaced-link {
    color: var(--purple);
    cursor: pointer;
    text-decoration: underline;
}

.replaced-mention {
    display: inline;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    padding: 2px 3px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--tag-bg-brown);
    color: var(--tag-text);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.user-modal {
    position: absolute !important;
    left: 0;
    bottom: calc(100% + 6px) !important;
    width: 230px;
}

.disactive path {
    fill: #616B85;
}

.active path {
    fill: #1175D3;
}

@media screen and (max-width: 480px) {
    .attachment {
        left: 16px;
    }

    .btns-container {
        right: 16px;
    }

    .form-container {
        padding-bottom: 8px;
    }
}