.wrap {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background: #FFFFFF1A;
    border: 1px solid #FFFFFF1A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrap svg {
    width: 24px;
    height: 20px;
}

@media screen and (max-width: 480px) {
    .wrap {
        width: 30px;
        height: 30px;
    }

    .wrap svg {
        width: 12px;
        height: 10px;
    }
}