.list-wrap {
    display: grid;
    grid-template-rows: auto 100%;
    padding-bottom: 20px;
    grid-template-columns: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    box-sizing: border-box;
    position: relative;
}

.list {
    margin-top: 3px;
    overflow: auto;
}

.list-item {
    padding: 14px 16px;
    background-color: transparent;
    user-select: none;
    transition: all .2s ease;
}

.selected-note.list-item {
    background-color: #302c5575;
}

.active-note,
.list-item:hover {
    background-color: #302C55;
}

.list-item h5 {
    color: #B8C8F0;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    font-weight: 500;
}

.name {
    color: #207ADA;
    font-size: 13px;
    font-weight: 400;
}

.date {
    color: #6F7B9A;
    font-size: 12px;
}

.icons-wrap {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.header p {
    color: #636175;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.item-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 5px;
}

.item-title h5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.heart {
    cursor: pointer;
}

.heart path {
    fill: transparent;
    stroke: #ffffff;
    transition: all .1s ease;
}

.heart.active path {
    fill: #FB433A;
    stroke: #FB433A;
}

.list-item p {
    margin-top: 0;
    margin-bottom: 0;
}

.date-name-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    margin: 9px 0 6px 0;
}

.dropdown {
    top: 100%;
    width: fit-content;
    overflow: hidden;
    min-width: 200px;
    right: 0;
    left: auto;
}

.dropdown h1 {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #100D26;
}

.dropdown h1 svg {
    cursor: pointer;
}

.add-note path:first-of-type {
    opacity: 1;
    fill: #ffffff;
}

.list-item::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 3px;
    opacity: 0;
    background-color: transparent;
    top: 0;
    transition: all .15s ease;
}

.selected-note.list-item::before {
    opacity: 1;
    background-color: #207ADA;
}

@media screen and (max-width: 480px) {
    .list-item h5 {
        font-size: 11px;
    }

    .date,
    .name {
        font-size: 8px;
    }

    .heart,
    .icon {
        width: 14px;
        height: 16px;
    }

    .header {
        flex-direction: row !important;
        align-items: flex-start;
        gap: 8px;
        flex-wrap: wrap;
    }
}