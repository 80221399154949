.gallery {
    overflow: hidden;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    margin-top: 8px;
    align-items: flex-start;
    width: 100%;
    max-width: -webkit-fill-available;
    transition: all 0.3s ease;
}

.gallery a {
    color: VAR(--white);
}

.gallery img,
.gallery video,
.gallery div {
    width: 220px;
    height: 140px;
    object-fit: cover;
    border-radius: 8px;
    flex-wrap: wrap;
}