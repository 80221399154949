.version-item-container {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    max-height: calc(100Vh - 323px);
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.header {
    padding: 16px;
    flex-direction: column;
}

.sub-heading {
    color: var(--text-heading);
    font-size: 14px;
    padding: 8px 0;
    display: flex;
}

@media screen and (max-width: 480px) {
    .header {
        padding: 0;
    }
}