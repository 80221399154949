.circular-progress-container {
    position: relative;
    min-width: 24px;
    min-height: 24px;

    width: fit-content;
    height: fit-content;
}

.circular-progress-svg {
    transform: rotate(-90deg);
}

.circular-progress-background {
    fill: none;
    stroke: #C5CEE4;
    stroke-width: 3;
}

.circular-progress-foreground {
    fill: none;
    stroke: #29CD3A;
    stroke-width: 3;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s;
}

.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333;
    font-weight: bold;
}

.icon {
    height: auto;
    position: absolute;
    inset: 0;
    bottom: 3px;
    margin: auto;
}

.hidden {
    opacity: 0;
    visibility: hidden;
}

.bounce {
    animation: bounce 1s infinite;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(-22%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        transform: translateY(10%);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}