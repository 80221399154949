.wrap {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding: 48px 0;
    background-color: var(--main-bg-blue);
    height: var(--height);
}

.btn {
    margin: 20px;
}