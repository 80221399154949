/* VersionPopover.css */

.version-popover {
    display: flex;
    flex-direction: column;
    color: var(--text-heading);
    font-size: 12px;
    padding: 9px;
    position: relative;
    text-align: center;
    z-index: 9;
    cursor: help;
}

.version-popover:hover .version-details {
    display: flex;
}

.version-details {
    display: none;
    flex-direction: column;
    color: var(--tooltip);
    font-size: 12px;
    padding: 9px;
    position: absolute;
    top: -16px;
    left: 96px;
    width: fit-content;
    text-align: left;
    border-radius: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: var(--room-item);
}

.triangle {
    position: absolute;
    left: -12px;
    bottom: 4px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10.5px 18.2px 10.5px 0;
    border-color: transparent var(--room-item) transparent transparent;
    transform: rotate(0deg);
}
