.reply-media-wrap {
    aspect-ratio: 1;
    max-width: 100px;
    border-radius: 8px;
    background-color: #302C55;
    color: var(--white-1);
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
}

.reply-media-wrap p {
    font-size: 10px;
}

.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

}

.download-items-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    overflow: hidden;
    position: relative;
    margin-top: 12px;
}

.article {
    position: relative;
    overflow: hidden;
}

.article img,
.article>div {
    height: 70px;
    aspect-ratio: 1.4;
    object-fit: cover;
    width: auto;
    border-radius: 8px;
}

@media screen and (max-width: 480px) {
    .reply-media-wrap p {
        margin: 0;
    }

    .article>div {
        height: fit-content;
        max-height: 70px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}