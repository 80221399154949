.container {
    width: 100%;
    height: 100%;
}

.header {
    padding: 16px;
    display: flex;
}

.logs-container {
    width: auto;
    height: calc(100vh - 313px);
    padding-top: 0px !important;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
}

.log-row-container {
    width: auto;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    background-color: var(--title-shadow);
    border-radius: 8px;
}

.description-label-container {
    display: flex;
    align-items: start;
    gap: 16px;
    width: fit-content;
}

.description-label {
    color: var(--white-3);
    font-size: 14px;
}

.small-label {
    color: var(--text-heading);
    font-size: 12px;
}

.profile-picture-img {
    height: 32px;
    width: 32px;
    border-radius: 8px;
    margin-right: 8px;
}

.character-limit {
    max-width: 250px;
    line-height: 1;
}

.log-details-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    width: auto;
}

.top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 26px 8px 16px;
    gap: 16px;
}

.top-container-right-seciton {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 16px;
}

/* ------ */

.filter-container-img:hover {
    width: 24px;
    cursor: pointer;
}

.date-filter-container {
    display: flex;
    align-items: center;
    gap: 16px;
}

/* table */
.table {
    width: 100%;
    padding: 16px 0px 24px 0px;
    background-color: var(--title-shadow);
    border-radius: 8px;
    margin-top: -14px;
}

.table>thead>td {
    border-bottom: 2px solid rgba(255, 255, 255, 1) !important;
    padding: 16px 16px !important;
}

.table-tr>td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
    padding: 16px 16px !important;
}

.arrow-area {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-left: auto;
    gap: 8px;
}

.arrow-area>img {
    height: 16px;
    width: 16px;
}

.pagination-container {
    display: flex;
    gap: 4px;
    padding: 0px;
}

.pagination-number-container {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    opacity: 0.5;
}

.pagination-number-container:hover {
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.pagination-number-container>img {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

@media screen and (max-width: 480px) {
    .date-filter-container {
        flex-direction: column;
        align-items: stretch;

    }

    .log-row-container {
        padding: 8px;
        gap: 8px;
    }

    .profile-picture-img {
        width: 24px;
        height: 24px;
    }

    .description-label b {
        font-size: 9px;
    }

    .small-label {
        font-size: 7px;
    }

    .arrow-area>img {
        width: 12px;
        height: 12px;
    }

    .table td {
        padding-block: 8px;
    }

    .table td:first-child {
        padding-left: 8px;
    }

    .table-tr>td {
        padding: 8px !important;
    }

    .table {
        padding: 0;
    }

    .log-row-wrap {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .small-label b {
        font-size: 7px;
    }

    .description-label-container {
        align-items: center;
    }
}