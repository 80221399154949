.headline-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--gray);
    text-align: start;
}

.title {
    margin: 0;
    margin-inline-end: auto;
    margin-inline-start: 6px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.28px;
}

.editIcon {
    margin-inline-end: 16px;
    margin-inline-start: auto;
}

.icon {
    cursor: pointer;
    transform: rotate(0);
    transition: all 0.2s ease;
}

.collapse-rooms-header {
    margin-right: auto;
    display: inline-flex;
    cursor: pointer;
    width: -webkit-fill-available;
    user-select: none;
}

.title-edit {
    display: inline-flex;
}

.section-rename {
    background-color: transparent;
    border-radius: 8px;
    outline: 0;
    border: 0;
    color: var(--white);
}
