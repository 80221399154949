.table-container {
    border-spacing: 0;
    padding-bottom: 50px;
    max-height: calc(
        100%
    ); /* 100% - 25vh Set the desired height for the container */
    overflow-y: auto; /* Enable vertical scrolling for the container */
    overflow-x: auto;
}

.table {
    border-spacing: 0;
    width: 100%;
}

.header {
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: var(--main-bg-blue);
    padding: 16px;
    text-align: left;
    gap: 16px;
    width: 100%;
}

tr {
    padding: 8px;
    position: relative;
}

tr:hover td {
    background-color: var(--room-item);
}

td {
    padding-top: 16px;
    padding-bottom: 16px;
    /* padding-right: 20px; */
}

td:first-child {
    padding-left: 16px;
    padding-right: 0;
}

th {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 20px;
}

th:first-child {
    padding-left: 16px;
    padding-right: 0;
}
