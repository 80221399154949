.Icon-container {
  position: relative;
  max-width: 64px;
  max-height: 64px;
}

.Icon-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}