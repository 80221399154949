.email {
    font-size: 14px;
    color: var(--text-heading);
    width: 250px;
}

tr:hover .hover-icon-container {
    visibility: visible;
}

/* ------------------------- cell Design ----------------------- */

.header-cell {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header-cell span {
    font-size: 14px;
    color: var(--text-heading);
    margin-right: 8px;
}

.header-cell img {
    width: 8px;
    color: var(--text-heading);
}

.user-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
}

.user-column-text {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.user-column>img {
    width: 40px;
    border-radius: 8px;
    height: 40px;
    object-fit: cover;
}

.user-sub {
    color: var(--text-secondary);
    size: 14px;
}

.email {
    font-size: 14px;
    color: var(--text-heading);
}

.status-wrapper {
    display: flex;
    align-items: center;
}

.status {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin-right: 8px;
}

.groups-wrapper {
    position: relative;
    display: flex;
    flex: 1;
    min-width: 300px;
}

.groups-wrapper .groups-item img {
    width: 32px;
    border-radius: 8px;
    margin-right: 4px;
}

.group-item-container {
    position: relative;
}

.group-name-container {
    visibility: hidden;
}

.group-item-container:hover .group-name-container {
    visibility: visible;
}

.groups-item {
    width: 32px;
    border-radius: 8px;
    margin-right: 4px;
    position: relative;
}

.highlightDiv {
    position: absolute;
    background-color: #000000cc;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    text-align: center;
}

.highlightDiv span {
    vertical-align: -webkit-baseline-middle;
    bottom: 0;
}

.hover-icon-container {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    display: flex;
    height: 100%;
}

.hover-icon-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--room-item);
    height: 100%;
    width: 64px;
    align-items: center;
    cursor: pointer;
}

.hover-icon-item>img {
    height: 16px;
    width: 16px;
}

.hover-icon-item:hover {
    background-color: var(--chat-background);
}

/* .block:hover {
    background-color: var(--red);
} */

.hover-icon-item span {
    font-size: 10px;
    margin-top: 4px;
}

.badgeClass {
    background-color: var(--dark-blue-2);
    color: var(--light-blue-1);
    border-radius: 4px;
    padding: 8px;
    padding-left: 24px;
    position: relative;
    font-size: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;
}

.badgeClass:hover {
    background-color: var(--dark-blue-2);
}

.badgeClass:hover img {
    background-color: var(--dark-blue-2);
    visibility: visible;
}

.badge {
    content: '';
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--blue-primary);
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.highlightDiv {
    position: absolute;
    background-color: #000000cc;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    text-align: center;
}

.highlightDiv span {
    vertical-align: -webkit-baseline-middle;
    bottom: 0;
}

.hover-icon-container {
    visibility: hidden;
    position: absolute;
    right: 0;
    z-index: 1;
    display: flex;
    height: 100%;
}

.hover-icon-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--room-item);
    height: 100%;
    width: 64px;
    align-items: center;
    cursor: pointer;
}

.hover-icon-item:hover {
    background-color: var(--chat-background);
}

.block:hover {
    background-color: var(--red);
}

.hover-icon-item span {
    font-size: 10px;
    margin-top: 4px;
}

.edit-image-popup-row {
    display: flex;
    gap: 8px;
    font-size: 14px;
    align-items: center;
    height: 40px;
    padding: 0px 8px;
    background: var(--dark-blue-3);
    border-bottom: 1px solid #615d8f;
    color: white;
    border-radius: 8px;
    line-height: 0;
    cursor: pointer;
}

.edit-image-popup-row:hover {
    opacity: 0.5;
}

.edit-image-popup-row:active {
    filter: brightness(1.2);
}

.edit-image-popup-container {
    position: absolute;
    z-index: 9;
    top: 10px;
    right: 0;
    background-color: transparent;
}

.edit-image-popup {
    background: var(--dark-blue-3);
    width: 150px;
    border-radius: 8px;
    transform: translateX(-12px);
}

@media screen and (max-width: 480px) {
    .email {
        width: 200px;
    }
}