.container {
    position: relative;
    background-color: var(--main-card-blue-head);
    border-radius: 16px;
    border: 1px dashed var(--dashed-border);
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    color: var(--text-heading);
    box-sizing: border-box;
}

.media-input {
    display: none;
}

.gallery-img {
    margin: auto;
    width: 22px;
}

.gallery-img path {
    fill: var(--text-heading)
}

.export-par {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-transform: capitalize;
    margin: 0 !important;
}

.one-item {
    justify-content: center;
    align-items: center;
    max-width: unset;
}

.one-item>img {
    width: -webkit-fill-available;
    height: inherit;
    object-fit: cover;
    max-height: 100px;
}

.scroll {
    overflow-y: auto;
    max-height: 400px;
    margin-bottom: 12px;
}

.fileInputAlternative {
    display: flex;
    justify-content: center;
    align-items: center;
}

.size-text {
    color: var(--white-20);
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 4px;
}

.export-icon path {
    fill: var(--text-heading);
}

@media screen and (max-width: 480px) {
    .scroll {
        max-height: min(90vh, 700px);
    }
}