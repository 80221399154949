.wrap {
    position: relative !important;
    overflow: hidden !important;
    border-radius: 8px !important;
    height: 4px !important;
    background-color: var(--main-bg-blue) !important;
    padding: 0 !important;
    transform-origin: left;
    width: 100%;
}

.inner {
    border-radius: 8px !important;
    height: 4px !important;
    width: 100% !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    bottom: 0;
    background-color: #FFFFFF !important;
    transform-origin: left;
    transition: all 0.2s ease;
}

.input {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    outline: none;
    margin: auto;
}

.input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 4px;
    height: 4px;
    background: transparent;
    cursor: pointer;
}

.input::-moz-range-thumb {
    width: 4px;
    height: 100%;
    background: transparent;
    cursor: pointer;
}