.sidebar {
    position: relative;
}

.sidebar::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: var(--bright-hr);
}

.nav-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    padding: 8px 12px 8px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24.998px;
    letter-spacing: -0.464px;
}

.nav-list-item .checked {
    margin-inline-start: auto;
}

.index-num {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.464px;
}

.grid {
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: min(1150px, 95vw);
    height: 80vh;
}

.body {
    padding: 16px 24px;
    max-height: 75vh;
    overflow: auto;
}

.info {
    padding: 32px 24px;
    color: var(--navy-1);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    max-width: min(333px, 24vw);
    letter-spacing: -0.464px;
}

.info-par {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.body h2 {
    color: var(--white);
}

.wrap {
    padding: 18px 30px;
}

.wrap h2 {
    margin-top: 0;
}

.spinner {
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: 4;
}

.fade {
    opacity: 0.4;
    transition: all .3s ease;
}

.clear {
    opacity: 1;
    transition: all .3s ease;
}
