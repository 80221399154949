.searchInput {
    /* width: calc(100% - 43px); */
    padding: 4px;
    padding-inline-start: 38px;
    border-radius: 8px;
    font-size: 14px;
    border: none;
    height: 32px;
    outline: 0;
    background-color: var(--main-card-blue-head);
    color: var(--purple);
    caret-color: var(--purple);
}

.searchInput:focus-visible {
    border: none;
    outline: 0;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--purple);
    transition: background-color 5000s ease-in-out 0s;
}

.search-icon {
    position: absolute;
    left: 4px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.filter-icon {
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.close-icon {
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    margin: auto 0;
    cursor: pointer;
}

.relative {
    position: relative;
}