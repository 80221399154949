.custom-input {
  padding: 12px;
  width: calc(100% - 24px);
  background-color: var(--room-item);
  color: var(--gray-4);
  position: relative;
  overflow-x: hidden;
  text-overflow: wrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border: 0;
  border-radius: 4px;
  outline: none;
}

.custom-input:focus-visible,
.custom-input:focus {
  outline: none;
  border: none;
}

.custom-input:-webkit-autofill,
.custom-input:-webkit-autofill:hover,
.custom-input:-webkit-autofill:focus,
.custom-input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--gray-4);
  box-shadow: inset 0 0 20px 20px var(--room-item);
  transition: background-color 5000s ease-in-out 0s;
}
