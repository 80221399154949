/* UserPermissinons.module.css */
.pvt-container {
    overflow: hidden;
    display: flex;
    position: relative;
    align-items: center;
    background-color: var(--right-sidebar);
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    margin: 16px;
}

.pvt-text {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    flex: 70%;
}

.subtitle {
    font-size: 14px;
    color: var(--text-secondary);
    margin-top: 4px;
}

.members-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px 0px;
    height: 250px;
    overflow-y: scroll;
}

.member-item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px 8px;
    width: fit-content;
    border-radius: 8px;
}

.member-item.selected {
    background-color: var(--blue-primary);
}

.tab-item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 4px;
    width: fit-content;
    border-radius: 4px;
    font-size: 12px;
}

.tab-item.selected {
    background-color: var(--blue-primary);
}

.role-item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px 8px;
    width: fit-content;
    border-radius: 8px;
}

.role-item.selected {
    background-color: var(--blue-primary);
}

.member-image {
    height: 24px;
    width: 24px;
    border-radius: 8px;
}

.permissions-container {
    display: flex;
    flex-direction: column;
    /* gap: 24px; */
    margin-top: 24px;
    height: 320px;
    width: 100%;
    padding-right: 16px;
    overflow-y: auto;
}

.permission-item {
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding: 16px 0px;
}

.search-input {
    all: unset;
    margin-top: 8px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    width: 80%;
    padding: 8px;
    border-radius: 8px;
    opacity: 1;
}

.search-input:focus {
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.warning-modal {
    background-color: orange;
    opacity: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px 4px;
    gap: 4px;
    font-size: 12px;
}

@media screen and (max-width: 480px) {

    .role-item,
    .pvt-text,
    .member-item {
        font-size: 10px;
    }

}