.dl-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: fit-content;
    position: relative;
    overflow: hidden;
    user-select: none;
    -webkit-user-drag: none;
    flex: 0 0 auto;
}


.icon {
    flex: 0 0 24px;
    height: auto;
}