.btn-wrap {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    margin-top: 16px;
    justify-content: space-between;
}

.modal {
    min-width: 400px;
}

.container {
    min-height: 200px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding-left: 30px;
    padding-right: 30px;
    gap: 20px;
}

.btn-wrap button {
    min-width: unset;
    flex: 1 0 calc(50% - 8px);
}

.header {
    background-color: #100D26 !important;
    font-weight: 600;
    font-size: 22px;
}

.input {
    margin-top: 4px;
    width: 100%;
    background-color: #100D26;
}

.flex {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.input-wrap {
    box-sizing: border-box;
    flex: 1;
}

.input {
    box-sizing: border-box;
}

.cancel {
    background-color: transparent !important;
}

.switch {
    margin-inline-start: auto;
}

.select {
    margin-top: 10px;
}