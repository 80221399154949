.new-chat-modal {
    max-width: min(450px, 80vw);
    width: fit-content;
}

.body-list {
    height: min(100vh, 400px);
    overflow-y: auto;
}

.body-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    position: relative;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 14px;
    word-break: break-word;
}

.body-item-wrap:not(:last-of-type) .body-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: var(--hr);
}

.person-badge {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    object-fit: cover;
    overflow: hidden;
}

.body-item-wrap {
    position: relative;
    overflow: hidden;
}

.body-item:hover {
    background-color: #302C55;
}

.body-item-wrap input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    margin: 0;
}

.body-item-wrap input[type='radio']:checked+li {
    background-color: var(--main-card-blue-head-opacity);
}

.badge {
    background-color: var(--blue-primary);
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 8px;
}

.badge>img {
    width: 10px;
}

.btn {
    margin: 16px;
    margin-top: 0;
    min-width: 273px;
}

.NewMessageInput {
    width: calc(100% - 16px - 24px);
    margin-left: 8px;
    margin-bottom: 8px;
}

.select {
    position: absolute !important;
    z-index: 2;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.select+.index {
    width: 32px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009FFF;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all .15s ease;
}

.select:checked+.index {
    opacity: 1;
    visibility: visible;
}

.select::before,
.select::after {
    display: none !important;
    opacity: 0;
    visibility: hidden;
}

.submit {
    margin: 24px;
    padding: 10px 12px;
    min-width: unset;
    height: fit-content;
}

.tabs-body {
    display: flex;
    flex-direction: column;

}

.no-results {
    padding: 8px 16px;
}

.modal-header {
    font-size: 14px;
    font-weight: 600;

}

.modal-header>img {
    width: 14px !important;
    height: 14px !important;
}