.card {
    width: calc(100% - 16px) !important;
    background-color: rgba(100, 100, 100, 0.1);
}
.hr {
    width: calc(100% + 16px); /* Increment in padding value   */
    height: 2px !important;
    margin-inline-start: -8px;
}
.ul {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.ul > li {
    background-color: rgba(100, 100, 100, 0.1);
}
.title {
    font-size: 14px;
    font-weight: 600;
}
.accordion {
    height: auto;
    opacity: 1;
    transition: all 0.2s;
}
.accordion-disactive {
    height: 0;
    opacity: 0;
}
.hr.accordion-disactive {
    margin: 0;
}
