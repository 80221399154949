.vertical-bar-container {
    width: fit-content;
    min-width: 30px;
    height: var(--height);
    background-color: var(--left-sidebar);
    margin: 0;
    position: relative;
}

.vertical-bar-container ul {
    height: var(--height);
    margin: 0;
    width: 96px;
    padding-bottom: 32px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    padding: 0;
}

.vertical-bar-container ul::-webkit-scrollbar {
    display: none;
}

.server-badge {
    width: 64px;
    height: 64px;
    object-fit: cover;
    z-index: 1;
    cursor: pointer;
    border-radius: 24px;
    background-color: var(--room-item);
}

.list-item {
    position: relative;
    margin-bottom: 16px;
    margin-block-end: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.list-item:last-of-type .server-badge {
    margin-bottom: 0;
    margin-block-end: 0;
}

.badge-img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    transition: visibility 0.2s, opacity 0.2s ease;
}

.badge-img-loader {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 24px;
    z-index: 1;
    transition: visibility 0.2s, opacity 0.2s ease;
    margin-bottom: 10px;
}

.badge-img:hover {
    opacity: 1 !important;
}

.horiz-cross,
.vertical-cross {
    background-color: var(--green-3);
    border-radius: 35px;
    position: absolute;
    inset: 0;
    margin: auto;
}

.vertical-cross {
    width: 3px;
    height: 24px;
}

.horiz-cross {
    height: 3px;
    width: 24px;
}

.relative {
    position: relative;
    background-color: var(--main-card-blue);
    border-radius: 24px;
}

.servers-list {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.servers-list>li {
    overflow: visible;
}

.indicator {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.type-badge {
    top: 0;
    right: 0;
    z-index: 2;
    background-color: var(--room-item);
}

.type-badge>img {
    width: 10px;
    height: 10px;
}

.tooltip {
    position: fixed;
    margin: auto;
    color: var(--tooltip);
    font-size: 13px;
    font-weight: 500;
    border-radius: 8px;
    background-color: var(--room-item);
    width: fit-content;
    height: fit-content;
    padding: 12px;
    z-index: 1000;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
}

.dropdown-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 0;
    border-bottom: 7px solid transparent;
    border-left: 7px solid transparent;
    border-right: 12px solid var(--room-item);
    border-top: 7px solid transparent;
    bottom: 0;
    margin: auto;
    right: 100%;
}

.list-item:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

.main-container {
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 23px;
    width: 96px;
    position: relative;
    z-index: 32;
}

.main-container::-webkit-scrollbar {
    visibility: hidden;
    display: none;
    width: 0;
    height: 0;
}

.logout-item {
    width: '100%';
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    padding: 12px 0;
}

.badge-img {
    border-radius: 24px;
}

@media screen and (max-width: 480px) {
    .server-badge {
        width: 48px;
        height: 48px;
    }

    .vertical-bar-container ul,
    .vertical-bar-container {
        height: calc(100dvh - 64px);
    }

    .vertical-bar-container ul,
    .main-container {
        width: fit-content;
    }

    .indicator {
        width: calc(100% - 4px);
    }

    .badge-img {
        border-radius: 18px;
    }

    .main-container {
        padding-top: 16px;
    }

    .list-item {
        padding-right: 8px;
        padding-left: 8px;
    }

    .tooltip {
        display: none;
    }
}