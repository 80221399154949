.toast {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color: var(--green);
    font-size: 14px;
    color: var(--keep-white);
    position: fixed;
    top: 12px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    width: fit-content;
    padding: 12px 24px;
    height: 24px;
}

@media screen and (max-width: 480px) {
    .toast {
        left: auto;
        right: 12px;
        padding: 8px 12px;
        font-size: 11px;
    }
}