.container {
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.heading-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
}

.text-wrapper>h2 {
    margin: 0 0 4px 0;
    font-size: 20px;
}

.text-wrapper>span {
    font-size: 16px;
    color: var(--text-heading);
}

.content-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 16px 0;
    height: 92%;
    overflow-y: auto;
}

.content-container::-webkit-scrollbar {
    display: none;
}

.content-container>span {
    font-size: 14px;
}

.server-sub {
    color: var(--text-secondary);
    size: 14px;
}

.username-wrapper {
    display: flex;
    gap: 8px;
}

.selected-server {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.selected-server>span {
    font-size: 12px;
}

.span-select-server {
    color: var(--blue-primary);
}

.span-correct-info {
    color: var(--text-heading);
}

.button-wrapper {
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
    background-color: #110d25;
    margin-top: auto;
    margin-bottom: 24px;
}

.form-success {
    box-sizing: border-box;
    border: 1px solid var(--green);
    border-radius: 8px;

    background-color: var(--green-5);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.form-success>span {
    font-size: 14px;
    color: var(--green);
}

.visible {
    /* display: block; */
    visibility: visible;
    opacity: 1;
    padding: 12px;
    margin-top: 16px;
}

.invisible {
    /* height: 0; */
    padding: 0px;
    margin-top: 0px;
    /* display: none; */
    visibility: hidden;
    opacity: 0;
}

.role-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 8px;
    margin: 12px 0;
    padding: 12px;
    background-color: var(--dark-input);
    border-radius: 8px;
    margin: 8px 0;
    box-sizing: border-box;
    color: var(--text-secondary);
    font-size: 16px;
}

.select:focus-visible {
    outline: 0;
}

.select {
    width: 100%;
    border-radius: 6px;
}

.select>div {
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
    outline: 0;
}