.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
}

.header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    /* flex: 1; */
    flex-direction: row;
}

.sub-heading {
    color: var(--text-heading);
    font-size: 14px;
    padding: 8px 0;
    display: flex;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    gap: 8px;
    /* margin-top: 16px; */
    /* overflow-y: scroll; */
}

.content h3 {
    /* padding: 8px 0; */
    margin: 0;
    /* margin-top: 16px; */
    margin-bottom: 6px;
    font-weight: 600;
    color: var(--text-heading);
}

.headingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    margin: 8px 0;
}

.appButton {
    margin: 16px 0;
}

.contentWrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    margin: 20px 48px;
}

.row-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start !important;
}

.new-keys-img {
    height: 72px;
    width: 72px;
    align-self: flex-start;
}

.row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 100%; */
    /* align-items: center; */
    /* padding: 16px; */
    gap: 16px;
}

.desc {
    size: 14px;
    color: var(--text-secondary);
    margin-bottom: 4px;
    /* margin-bottom: 10px; */
}

hr {
    background-color: var(--bright-hr);
    height: 2px;
    /* color: var(--bright-hr); */
    width: 100%;
    margin-bottom: 16px;
}

.pvt-text {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    flex: 70%;
    /* width: 100%; */
}

.pvt-container {
    display: flex;
    /* max-width: 100%; */
    /* width: 100%; */
    position: relative;
    align-items: center;

    margin-bottom: 20px;
    justify-content: space-between;
    /* flex: 1; */
    margin-top: 8px;
}

.re-encrypt-container {
    background-color: var(--chat-background);
    border-radius: 8px;
    padding: 16px;
}

.new-key {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 100%; */
    align-items: center;
    padding: 20px;
    margin-bottom: 16px;
    gap: 16px;
    border: 2px solid var(--room-item);
    border-radius: 16px;
}

.flex-row {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}

.flex-row button {
    min-width: unset;
}

@media screen and (max-width: 480px) {
    .header {
        flex-direction: column;
    }

    .content {
        box-sizing: border-box;
        overflow-x: hidden;
    }

    .row-wrapper {
        width: 100%;
        flex-direction: column;
        gap: 0;
    }

    .appButton {
        width: 100%;
        margin: 0;
        margin-top: 16px;
    }

    .inputWrapper {
        margin: 0;
    }

    .new-key {
        padding: 12px;
        flex-direction: column;
    }

    .new-keys-img {
        width: 30px;
        height: 30px;
    }

}