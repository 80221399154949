.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
}

.header {
    padding: 16px;
    flex-direction: column;
}

.search-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
}

.search-row>.text-input {
    margin-right: 16px;
}

.filter-row {
    display: flex;
    align-items: center;
    gap: 40px;
}

.side-modal {
    height: 100%;
    width: 400px;
    background-color: var(--left-sidebar);
    color: white;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
    border-radius: 12px 0px 0px 12px;
    margin: 0px;
}

.motion-wrap {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: var(--room-header);
    max-width: 400px;
    width: 100%;
    z-index: 10;
}

.membersTabel {
    max-height: calc(100vh - 290px);
    max-height: calc(100dvh - 290px);
    padding-bottom: 12px;
}

.server-members-form-inputs>div {
    width: 100%;
}

.right-motion-container {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    max-width: 450px;
    background-color: var(--dark-blue-2);
    z-index: 10;
}

@media screen and (max-width: 480px) {
    .header h2 {
        font-size: 12px;
    }

    .header {
        padding: 12px 8px;
    }

    .side-modal {
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        background-color: var(--main-bg-blue);
    }
}