.text-area,
.server-name {
    background-color: var(--left-sidebar);
}

.server-name {
    margin: 8px 0;
}

.scroll {
    max-height: 400px;
    overflow-y: scroll;
    padding: 0;
}

.error {
    font-size: 12px;
    color: var(--red);
}

.modal {
    overflow-y: auto;
}

.modal p {
    font-size: 12px;
    color: var(--navy-1);
}

.modal h2 {
    font-size: 14px;
    font-weight: 600;
}

.input-area {
    position: relative;
}

.input-area>img {
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 16px;
    z-index: 2;
    height: 16px;
    opacity: 0.3;
}

.custom-input {
    width: 100%;
    box-sizing: border-box;
    color: var(--text-heading);
}

textarea.custom-input {
    resize: vertical;
    max-height: 150px;
}

.custom-input::placeholder {
    color: var(--text-heading);
}

.line-after {
    position: relative;
    padding-bottom: 4px;
    color: var(--white);
}

.line-after::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--hr);
    bottom: 0;
    left: 0;
}

.d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-block-start: 32px;
}

.appButton {
    width: auto;
    min-width: 180px;
    border-radius: 4px;
}

.private-choise {
    position: relative;
}

.checkbox-indicator {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 18px;
    padding: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 16px;
    background-color: var(--purple-light);
    transition: all 0.3s ease;
}

.private-choise>input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 3;
    cursor: pointer;
    transition: all 0.3s ease;
}

.check-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--navy-1);
    transform: translateX(0);
    transition: all 0.3s ease;
}

.private-choise>input:checked~.checkbox-indicator .check-dot {
    transform: translateX(100%);
    background-color: var(--blue-primary);
}

.privacy-title {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
}

.add-members-section {
    position: relative;
}

.add-members {
    margin-inline-start: auto;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.search-relative {
    position: relative;
    height: 1px;
}

.mt-0 {
    margin-top: 0;
}