.owner-image-popup-container {
    position: absolute;
    z-index: 9;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    max-height: 300px;
}
.owner-image-popup-container::-webkit-scrollbar-thumb {
    background-color: var(--dark-blue-3);
}

.bottom {
    transform: translateX(100%);
    bottom: 0;
    overflow: hidden;
}

.read-image-container {
    position: absolute;
    z-index: 9;
    right: 0;
    transform: translateX(100%);
}

.owner-image-popup {
    background: var(--dark-blue-3);
    bottom: 0;
    width: 230px;
    border-radius: 8px;
    transform: translateX(14px);
}
.width {
    width: 230px;
    transform: translateX(0);
}

.triangle {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 12px 20.8px 12px 0;
    border-color: transparent var(--dark-blue-3) transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    bottom: 8px;
    transform: translateX(-10px);
    z-index: -1;
}

.owner-image-popup-row {
    display: flex;
    gap: 8px;
    font-size: 14px;
    align-items: center;
    height: 40px;
    padding: 0px 8px;
    background: var(--dark-blue-3);
    border-bottom: 1px solid #615d8f;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    line-height: 0;
    cursor: pointer;
}

.space {
    height: 48px;
    justify-content: space-between;
}

.owner-image-popup-row:last-child {
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    /* color: #fb433a; */
}
.icon {
    width: 32px;
    height: 32px;
    border-radius: 10px;
}
