.app-button {
    display: flex;
    min-width: 358px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    border-radius: 8px;
}

.app-button.small {
    min-width: 130px;
    font-size: 14px;
}

.app-button.medium {
    min-width: 156px;
    font-size: 14px;
}

.disabled {
    background-color: var(--purple);
    color: var(--text-primary);
    pointer-events: none;
}

.app-button.primary {
    background-color: var(--blue-primary);
    color: var(--text-primary);
}

.app-button.secondary {
    background-color: transparent;
    color: var(--text-primary);
    border: 1px solid var(--text-primary);
}

.app-button.transparent {
    background-color: transparent;
    color: var(--text-primary);
}

.whiteWithBorder {
    background-color: transparent;
    border: 1px solid var(--navy-3);
    color: var(--navy-3);
}

.left-icon {
    display: flex;
    align-items: center;
}
@media screen and (max-width: 480px) {
    .app-button { 
        min-width: 200px;
    }
}