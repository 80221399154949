.actions-container {
    display: inline-flex;
    height: fit-content;
    width: fit-content;
    /* gap: 4px; */
    margin-inline-start: auto;
    position: absolute;
    top: 0px;
    right: 10px;
    background-color: var(--dark-blue-2);
    border-radius: 4px;
    padding: 0px 4px;
}
