.chat-area-container {
    height: calc(80vh - 16px);
    width: 100%;
    overflow: hidden;
    border: 2px solid var(--drop-down-line);
    border-radius: 16px;
}

.messages-area-container {
    height: 100%;
}

.chatCard {
    width: auto;
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--room-item);
}

.notification-list {
    display: flex;
    flex: 1;
    /* height: 100%; */
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}

.room-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--dark-blue-3);
    padding: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.room-header>img {
    margin-right: 8px;
    height: 32px;
    width: 32px;
    object-fit: contain;
}

.room-name-header {
    /* padding: 8px; */
    font-size: 22px;
    margin: 0;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--keep-white);
    text-transform: capitalize;
}

.strong-hr {
    background-color: var(--bright-hr);
    margin: 0;
    height: 1px;
}

.server-padding-container {
    /* width: -webkit-fill-available;    */
    /* height: var(--height); */
    overflow: hidden;
    /* height: calc(var(--height) - 16px); */
    margin: 8px;
    display: inline-flex;
    gap: 8px;
    width: 100%;
}

.filter-container {
    background-color: var(--dark-blue-3);
    margin: 16px;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 56px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.filter-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.filter-item {
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 40px;
    width: 100px;
    margin-right: 10px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.active {
    background-color: var(--darkNavy-to-lightNavy);
}

.badge {
    background-color: var(--red);
    border-radius: 4px;
    position: relative;
    color: var(--keep-white);
    margin-left: 8px;
}

.markAllRead-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
}

.markAllRead {
    color: var(--gray-3);
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.tab-par {
    color: var(--tooltip);
}

.active .tab-par {
    color: var(--keep-white);
}

.server-padding-container {
    position: absolute;
    right: 50px;
    top: 50px;
    z-index: 100;
    width: 450px;
}

@media screen and (max-width: 480px) {
    .server-padding-container {
        margin: 0;
        box-sizing: border-box;
        left: 0;
        right: 0;
        top: 0;
        max-height: 100vh;
        max-width: 100vw;
        overflow: hidden;

    }

    .chat-area-container {
        width: 100vw;
        width: 100dvw;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
        height: 100dvh;
        box-sizing: border-box;
    }

    .filter-container {
        margin: 8px;
    }

    .room-header .close {
        margin-inline-start: auto;
        width: 20px;
        height: 20px;
    }
}