.dropdown {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 2;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    border-radius: 8px;
    background: var(--room-item);
    color: var(--drop-down-item);
    width: 100%;
}

.dropdown>ul {
    position: relative;
}

.dropdown>ul>li {
    text-transform: capitalize;
    padding: 8px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    gap: 8px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    line-height: 24.998px;
    letter-spacing: -0.464px;
    position: relative;
    transition: all .3s ease;
}

.dropdown-arrow {
    height: 0;
    width: 0;
    position: absolute;
    right: 12px;
    top: -20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--room-item);
    border-top: 10px solid transparent;
}


.dropdown h1 {
    padding: 8px 16px;
    margin: 0;
    color: var(--secondary-f-6, #F6F6F6);
    font-size: 18px;
    font-weight: 700;
    line-height: 24.998px;
    letter-spacing: -0.464px;
}

.dropdown>ul>li:not(:last-of-type)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--drop-down-line);
}

.dropdown>ul>li:hover {
    background-color: var(--dropdown-bg-hover) !important;
    color: var(--keep-white);
}
/* 
.dropdown>ul>li:hover svg {
    fill: var(--keep-white);
} */

/* .dropdown>ul>li:last-of-type {
    color: var(--red);
} */

#extra-item:hover {
    background-color: transparent !important;
}