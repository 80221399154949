.container {
    /* position: absolute; */
    z-index: 1;
    top: -20px;
    width: 100%;
    margin-bottom: -4px;
    /* box-sizing: border-box; */
}
.container-wrapper {
    display: flex;
    /* gap: 4px; */
    background-color: var(--room-item);
    width: 100%;
    min-height: 40px;
    border-radius: 4px;
}
.tooltiptext {
    visibility: hidden;
    max-width: 120px;
    width: max-content;
    background-color: var(--chat-background);
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    overflow-wrap: normal;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 10;
    top: -30px;
}
.button-container {
    padding: 4px;
    position: relative;
    margin: 4px 1px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 24px;
    border-radius: 4px;
    user-select: none;
}

.button-container:hover .tooltiptext {
    visibility: visible;
}
.button-container:hover {
    background-color: var(--chat-background);
}
