.navbar-text {
    font-size: 14px;
}

.container {
    width: 90vw;
    width: 90dvw;
    height: 90vh;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: var(--main-bg-blue);
    z-index: 99;
}

.modal-container {
    display: flex;
    height: 100%;
}

.left-navbar {
    padding-top: 16px;
    flex: 20%;
    display: flex;
    height: 100%;
    flex-direction: column;
    border-right: 1px solid var(--bright-hr);
    min-width: 250px;
}

.navbar-item {
    padding: 14px;
    display: flex;
    align-items: center;
}

.active {
    background-color: var(--room-item);
}

.navbar-icon {
    margin-right: 16px;
}

.right-content {
    display: flex;
    flex: 100%;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
}

@media only screen and (max-width: 720px) {
    .left-navbar {
        flex: none;
        width: auto;
        min-width: auto;
    }

    .navbar-icon {
        margin-right: 0;
    }

    .navbar-text {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .container {
        width: 100dvw;
        height: 100dvh;
    }

    .right-content {
        padding-inline-end: 0;
        padding-right: 0;
    }
}