/* src/components/TitleBar.css */

.title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--chat-background);
    color: var(--white);
    /* padding: 8px 16px; */
    user-select: none;
    height: 40px;
    border-bottom: 1px solid var(--bright-hr);
    /* -webkit-app-region: drag; */
    position: relative;
    z-index: 999;
}

.title-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-left: 16px;
    align-items: center;
    gap: 8px;
    -webkit-app-region: drag;
}

.title {
    font-size: 16px;
}

.icon {
    width: 18px;
    height: 18px;
    padding: 8px 8px;
}

.window-buttons {
    display: flex;
    /* gap: 20px; */
}

.button {
    cursor: pointer;
    padding: 0 8px;
}

.button:hover {
    background-color: var(--tabs-header);
}
/*  */
