.modal {
    width: 640px;
    max-width: 100dvw;
    overflow-x: hidden;
}

.d-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.badge {
    background-color: var(--room-item);
    position: relative;
    margin: auto;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 10px;
    color: var(--date-color);
}

.icon>img {
    width: 32px;
    height: 32px;
    border-radius: 12px;
}

.d-flex p {
    margin: 0;
    font-size: 13px;
    padding: 0px;
}

.modal h2 {
    font-size: 14px;
}

.date {
    font-size: 10px;
    padding: 2px 6px;
    margin-inline-start: auto;
}


.search-list {
    height: max(57vh, 403px);
    overflow: auto;
    width: 100%;
    flex: 1;
    position: relative;
}

.d-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.d-flex p {
    padding: 0;
}

.search-item {
    cursor: pointer;
}

.search-text {
    word-break: break-word;
    margin-top: 12px !important;
}

.empty-result {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    min-height: 200px;
    width: 100%;
    height: 100%;
}

.failsure-img {
    position: absolute;
    inset: 0;
    height: 200px;
    width: 200px;
    margin: auto;
    z-index: -1;
}

.search-img {
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: -1;
}

.no-result {
    color: var(--red);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.search-result {
    display: flex;
}


.result {
    color: var(--notifications-line);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.par {
    color: var(--notifications-line);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.search-input>input {
    padding: 4px 8px !important;
    width: calc(100% - 16px);
    background-color: var(--main-card-blue);
}

.message>span {
    font-size: 11px;
    font-weight: 400;
    padding-inline-start: 10px;
    position: relative;
    margin-inline-start: 10px;
    transition: all .2s ease;
}

.message>span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    background-color: var(--date-color);
    width: 6px;
    height: 6px;

}

.message-channel {
    color: var(--date-color);
}

.message-private {
    color: var(--tag-bg-brown);
}

.message-private::before {

    background-color: var(--tag-bg-brown) !important;

}

.user-modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 280px;
    height: 100%;
    display: flex;
}

.results-container {
    position: relative;
    display: flex;
    height: max(57vh, 403px);
    justify-content: space-between;
    align-items: top;
}

.user-modal,
.user-modal>div:first-of-type {
    position: static;
    background-color: transparent;
}

.user-modal>div>img {
    border-radius: 4px;
    width: 280px;
}

.text-color {
    color: var(--gray);
}

@media screen and (max-width: 480px) {
    .modal {
        width: 100dvw;
        height: 100dvh;
    }
}