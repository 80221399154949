.disabled-input-container {
    position: relative;
    padding: 12px;
    height: min-content;
    margin-top: 0;
    margin-bottom: 0;
}

.disabled-input {
    padding: 12px 12px 12px 12px;
    width: auto;
    background-color: var(--room-item);
    color: var(--gray-4);
    position: relative;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 0;
    border-radius: 4px;
    outline: none;
    bottom: 0;
    word-break: break-word;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img {
    height: 24px;
    width: 24px;
}

.permission-text {
    align-self: center;
    color: var(--text-3);
    line-height: 0;
    font-size: 12px;
}

.sendBTN {
    opacity: 0.5;
}