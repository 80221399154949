.message-container {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    margin-top: auto;
    transition: ease-in-out 0.4s;
    scroll-behavior: smooth;
    height: 100%;
}

.messages-list {
    overflow-y: auto;
    padding-inline-end: 12px;
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
}

.load-more {
    width: 100%;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 10px;
    z-index: 5;
}

.rendered-message-list:focus-visible {
    outline: none !important;
}

.message-item-div {
    transition: ease-in 0.2s;
}

.fade-entering {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms ease, transform 300ms ease;
}

.fade-entered {
    opacity: 1;
    transform: translateY(0);
}

.fade-exiting {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease, transform 300ms ease;

}

.fade-exited {
    opacity: 0;
    transform: translateY(20px);
}