.connections-section {
    width: 380px;
    overflow: hidden;
    background-color: var(--right-sidebar);
    border-radius: 8px;
    height: calc(var(--height) - 16px);
    box-sizing: border-box;
    overflow: auto;
}

.offline {
    opacity: 0.5;
}

.main-ul {
    /* height: calc(var(--height) - 89px); */
    height: calc(var(--height) - 16px);
    overflow: hidden;
    overflow-y: auto;
}

.main-ul>li,
.main-ul>li>div {
    overflow: hidden;
}

/*  */
.container {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    overflow-y: hidden;
}

.header {
    width: calc(100% - 32px);
    margin: 0;
    background-color: var(--dark-1);
    padding: 10px 16px;
    color: var(--gray-5);
    display: inline-flex;
    cursor: pointer;
}

.header>p {
    margin: 0;
    padding: 0;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.people-list {
    max-height: calc(100% - 59px);
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.userItem {
    padding: 10px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    position: relative;
    /* z-index: 3; */
}

.arrowDownIcon {
    margin-right: 4px;
    width: 5px;
}

.badge {
    position: relative;
    border-radius: 6px;
    background: var(--room-item);
    padding: 0 4px;
    color: var(--purple-2);
    margin-left: 12px;
    z-index: 3;
}

.onlineBadge {
    background-color: var(--green-2);
    color: var(--main-bg-blue);
}

.person-badge {
    width: 32px;
    height: 32px;
}

.person-badge>img {
    border-radius: 12px;
    object-fit: cover;
}

.userItem {
    display: flex;
    flex: 1;
}

.username {
    display: flex;
    flex: 1;
    font-weight: 400;
    font-size: 14px;
}

.loader {
    border-radius: 12px;
    overflow: hidden;
}

.no-result {
    font-size: 14px;
    text-align: center;
    color: var(--purple);
}

.input-container {
    display: flex;
    align-items: start;
    gap: 8px;
    border-bottom: 2px solid rgb(255, 255, 255, 0.15);
    padding: 18px 8px;
    background-color: var(--dark-1);
}

.input-container>img {
    width: 18px;
}

.input-container>input {
    all: unset;
    width: 100%;
    height: 100%;
    margin-top: 2px;
}

.searchUser::placeholder {
    color: var(--gray-5);
    opacity: 1;
}

.spin-wrap {
    margin: 12px auto;
}

.search-icon path {
    stroke: var(--gray-5);
}

@media screen and (max-width: 480px) {
    .connections-section {
        width: 100%;
        height: calc(100dvh - 72px);
    }
}