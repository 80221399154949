.contentWrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
}

.headingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    width: 358px;
    flex: 0.8;
}

.version {
    font-size: 12px;
    margin: 8px 0px;
    color: var(--text-heading);
    text-align: center;
}

.logo {
    height: 25vh;
}

@media screen and (max-width: 480px) {
    .logo {
        height: 150px;
    }

    .contentWrapper {
        justify-content: space-evenly;
        padding: 20px;
    }
    .inputWrapper { 
        flex: none;
    }
}