.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    position: sticky;
    top: 100%;
}

.pagination .par {
    cursor: pointer;
    font-size: 12px;
    color: var(--navy-1);
    margin: 0;
    position: relative;
    transition: all .2s ease;
}

.pagination .par:hover,
.page-numbers li:hover {
    color: var(--white);
}

.page-numbers {
    display: flex;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
    align-items: center;
    transition: all .3s ease;
}

.page-numbers li {
    margin: 0;
    color: var(--navy-1);
    cursor: pointer;
    transition: all .3s ease;
}

.in-range,
.activePage {
    display: block;
    transition: all .3s ease;

}

.pageNumber {
    display: none;
    opacity: 0;
    transition: all .3s ease;
}

.page-numbers li.activePage {
    color: var(--white);
    transform: scale(1.1);
}