.filter-icon-container {
    position: relative;
}

.filter-container-img {
    width: 24px;
    cursor: pointer;
}

.filter-role-modal {
    position: absolute;
    z-index: 50;
    right: 0;
    width: 368px;
    padding: 8px;
    background: var(--tabs-management-header);
    border-radius: 8px;
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 50px -12px;
}

.triangle {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 12px 20.8px 12px;
    border-color: transparent transparent var(--tabs-management-header) transparent;
    transform: rotate(0deg);
    position: absolute;
    top: 0px;
    right: 0;
    transform: translateY(-10px);
    z-index: -1;
}

.filter-role-modal-btn-container {
    width: 100%;
}

.date-label {
    color: var(--text-heading);
    font-size: 12px;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filter-role-modal-btn {
    display: flex;
    justify-content: end;
}

.filter-role-modal-btn>span:hover {
    text-decoration: underline;
}

.filter-role-modal-btn>span {
    padding: 12px 8px;
    cursor: pointer;
    color: var(--table-header-color);
    font-weight: 600;
}

.filter-row-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    min-height: fit-content;
    max-height: 400px;
    align-items: start;
    justify-content: start;
    gap: 16px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 10px;
}

.filter-role-modal-row {
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 12px 8px;
    border-radius: 8px;
    width: 145px;
}

.filter-role-modal-row:hover {
    opacity: 0.5;
}

.filter-modal-row-name {
    cursor: pointer;
    display: block;
    width: 100%;
}

.search-user-wrap {
    opacity: 0.5;
    display: flex;
    align-items: center;
}


@media screen and (max-width: 480px) {
    .search-user-wrap {
        display: grid;
        grid-template-columns: calc(100% - 60px) 60px;
        gap: 10px;
        box-sizing: border-box;
    }

    .filter-role-modal {
        right: -70px;
        margin-top: 10px;
        max-width: calc(100vw - 100px);
        width: calc(100vw - 100px);
    }

    .triangle {
        right: 70px;
    }

    .filter-role-modal-row {
        width: 100%;
        padding: 8px;
        justify-content: flex-start;
        align-items: center;
    }

    .filter-role-modal-row label {
        width: fit-content;
    }

    .filter-row-container {
        gap: 4px;
    }
}