.tabs-header {
    padding: 8px;
    border-radius: 8px;
    margin: 8px;
    background: var(--tabs-header);
}

.tabs-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

}

.tab-item {
    display: flex;
    width: 168px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: var(--white);
    background-color: transparent;
    position: relative;
    overflow: hidden;

}


.tab-item input[type="radio"],
.body-item-wrap input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    margin: 0;
}

.active-tab {
    text-align: center;
    padding: 8px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    font-weight: 500;
    background-color: transparent;
    margin: 0;
    transition: all .3s ease;
}

.tab-item input[type="radio"]:checked+.active-tab,
.body-item-wrap input[type="checkbox"]:checked+li {
    background-color: var(--tooltip);
    color: var(--tabs-text-header);
}

@media screen and (max-width: 480px) {
    .tab-item {
        width: 126px;
    }
}