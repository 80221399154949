.circle {
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background-color: var(--blue-primary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    font-size: 10px;
    color: var(--white);
}
