.header {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #1D1936;
    font-weight: 600;
    color: #636175;

}

.icons-wrap {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.list {
    margin-top: 6px;
    height: calc(-150px + 100vh);
    overflow-y: auto;
}

.list-item {
    padding: 12px 25px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    color: var(--white);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    transition: all .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.active,
.list-item:hover {
    background-color: #302C55;
}


.settings-icons-wrap {
    margin-left: auto;
    margin-right: -10px;
    display: flex;
    justify-content: flex-end;
    gap: 4px;
    align-items: center;
}

.edit-icon,
.trash-icon {
    visibility: hidden;
    opacity: 0;
    transition: all .15s ease;
}

.list-item:hover .edit-icon,
.list-item:hover .trash-icon {
    visibility: visible;
    opacity: 1;
}

@media screen and (max-width: 480px) {
    .list-item { 
        padding: 10px 12px;
        font-size: 10px;
    }
}