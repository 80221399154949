.details-container {
    display: grid;
    gap: 2px;
    grid-template-rows: 38px calc(100% - 106px);
}

.icons-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    padding: 6px 16px;
}

.input,
.input:focus-visible {
    outline: 0;
    background-color: transparent;
    border: 0;
    color: #E4E5F1;
}

.input::placeholder {
    color: #e4e5f19f;
}

.search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    background-color: #181436;
    border-radius: 8px;
    padding: 4px 8px;
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
}

.search-icon {
    width: 20px;
}

.search-icon path {
    stroke: #E4E5F1;
}

.body {
    background-color: #302C55;
    padding: 30px 30px;
}

.body h5 {
    color: #B8C8F0;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    font-weight: 500;
}


.search-clear-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #999;
}

.search-clear-button:hover {
    color: #333;
}

.search-close {
    opacity: 0;
    visibility: hidden;
    transform: translateX(10px);
    cursor: pointer;
    transition: all .1s ease;
}

.search-close.visible {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}

.body {
    overflow: hidden;
}

.body h5 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

.heart path {
    fill: transparent;
    stroke: #ffffff;
    transition: all .1s ease;
}

.heart.active path {
    fill: #FB433A;
    stroke: #FB433A;
}

.save-state {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    margin-inline-end: auto;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter';
}

.disabled {
    cursor: initial;
}

.saving {
    cursor: wait;
}

.saving-icon {
    animation: rotate 1s infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 480px) {
    .body {
        padding: 12px;
    }

    .search {
        gap: 8px;
        width: 100%;
    }
}