.btn-wrap {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    margin-top: 16px;
    justify-content: space-between;
}

.modal {
    min-width: 400px;
}

.container {
    min-height: 250px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    padding-left: 30px;
    padding-right: 30px;
    gap: 20px;
}

.btn-wrap button {
    min-width: unset;
    flex: 1 0 calc(50% - 8px);
}

.header {
    background-color: #100D26 !important;
    font-weight: 600;
    font-size: 22px;
}

.input {
    margin-top: 4px;
    width: 100%;
    background-color: #100D26;
}

.flex {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: end;
    gap: 8px;
}

.input-wrap {
    box-sizing: border-box;
    flex: 1;
}

.input {
    box-sizing: border-box;
}

.cancel {
    background-color: transparent !important;
}

.error.input {
    border: 1px solid red !important;
}

.error {
    color: red;
}

p.error {
    font-size: 12px;
    margin-bottom: 0;
    position: absolute;
    top: 0px;
    left: 30px;
    transition: all .2s ease;
}

.flex aside {
    left: -10px;
    z-index: 40;
    top: calc(100% + 5px);
}

.emoji {
    font-size: 22px;
    user-select: none;
    position: absolute;
    left: 7px;
    margin: auto;
    bottom: 4px;
    z-index: 3;
}

.success {
    color: var(--green);
    text-align: center;
}

.emoji-required circle {
    fill: red;
}

.emoji-required path {
    stroke: red;
}

.input-wrap:first-of-type {
    margin-top: 40px;
}

.input-wrap {
    position: relative;
}

.input-wrap label {
    padding-bottom: 10px;
}

.select {
    margin-top: 8px;
}

.section-name {
    padding-inline-start: 40px;
}