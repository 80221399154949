.modal-container {
    background-color: var(--main-bg-blue);
    position: fixed;
    inset: 0;
    z-index: 33;
    margin: auto;
    width: fit-content;
    height: fit-content;
    border-radius: 8px;
    min-width: 300px;
    max-width: 100dvw;
    max-height: 100dvh;
    display: grid;
    grid-template-rows: auto 1fr;
}

.modal-header {
    background-color: var(--main-card-blue-head);
    font-size: 20px;
    font-weight: 600;
    color: var(--msg-actions);
    display: flex;
    justify-content: flex-start;
    position: relative;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px 8px 0 0;
    gap: 8px;
}

.modal-header>img:first-of-type {
    width: 24px;
    height: 24px;
}

.modal-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    left: 0;
    background-color: var(--bright-hr);
}

.card-body {
    border-radius: 0 0 8px 8px;
    padding: 16px;
}

.close {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-inline-start: auto;
}

.backdrop {
    background: rgba(0, 0, 0, 0.5);
}

.modal-header-title {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media screen and (max-width: 480px) {
    .modal-container {
        width: 100dvw;
        height: 100dvh;
    }

    .modal-header-title {
        font-size: 1rem;
    }
}