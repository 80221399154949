.btnJoin {
    min-width: fit-content;
    width: 150px;
    min-height: fit-content;
    height: 100%;
    color: var(--keep-white);
    font-size: 16px;
    font-weight: 500;
}

.owner-image,
.owner-image>img {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    position: relative;
    z-index: 0;
    cursor: pointer;
}

.message-line-container {
    padding: 8px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* gap: 10px; */
    font-size: 14px;
    position: relative;
    color: var(--white-3);
    background-color: transparent;
    transition: all 0.3s ease;
    height: max-content;
    /* background-color: red; */
}

.message-line-container:hover {
    background-color: var(--room-item);
}

.message-line-container:hover article>div>div,
.message-line-container:hover article>div {
    background-color: #3f3b6b;
}

.message-line-container:hover .reactions-badge {
    background-color: var(--reaction-on-hover);
}

.message-line-container p {
    margin: 0;
}

/* .showActionBtns, */
.message-line-container:hover .action-buttons {
    visibility: visible;
    display: block;

}

.action-buttons {
    visibility: hidden;
    display: none;
}

.owner-title {
    text-transform: capitalize;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.dot {
    position: relative;
    color: var(--message-time);
    font-size: 14px;
    padding-left: 10px;
}

.dot::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--message-time);
}

.d-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: fit-content;
    gap: 10px;
    position: relative;
    margin-top: 10px;
}

.reactions-badge {
    position: relative;
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    font-size: 12px;
    border-radius: 8px;
    background: var(--darkNavy-to-lightNavy);
    color: var(--keep-white);
    cursor: pointer;
    transition: all 0.3s ease;
}

.font-12 {
    font-size: 12px;
    width: -webkit-fill-available;
    margin-left: 10px;
}

.editMsgInput {
    background-color: var(--main-card-blue-head-opacity);
}

.relative {
    position: relative;
}

.emoji-btn {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.reply-chat {
    border-radius: 8px;
    background: var(--drop-down-line);
    opacity: 0.6;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    height: auto;
    max-width: 350px;
    overflow: hidden;
}

.main-message-content {
    word-break: break-word;
}

.reaction-users-container {
    display: none;
    position: absolute;
    top: 16;
    z-index: 99;
}

.reaction-users {
    transform: translateY(-16px);
    height: fit-content;
    max-height: 140px;
    width: max-content;
    background-color: var(--room-item-opacity);
    backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.6) 0px 25px 50px -12px;
    margin-top: 22px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow-y: auto;
    padding: 8px;
    border-radius: 8px;
    color: var(--white);
    font-size: 11px;
    font-weight: 400px;
}

.reactions-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.reactions-container:hover .reaction-users-container {
    display: block;
}

.reaction-user-img {
    width: 12px;
    height: 13px;
    border-radius: 50%;
}

.react-user-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.react-user-item:last-of-type {
    margin-bottom: 0;
}

.message-line-container .mt-8 {
    margin-top: 8px;
}

.user-banned-badge {
    font-size: 9px;
    background: red;
    color: #fff;
    padding: 2px;
    border-radius: 2px;
    opacity: 0.7;
    line-height: 11px;
}

.context-menu {
    position: fixed;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    padding: 8px;
    z-index: 1000;
}

.context-menu div {
    cursor: pointer;
    padding: 8px;

    &:hover {
        background-color: #f0f0f0;
    }
}

.active-thread::before {
    content: '';
    position: absolute;
    left: 40px;
    top: 60px;
    width: 2px;
    border-radius: 4px;
    background-color: #4b587857;
    height: calc(100% - 94px);
}

.active-thread::after {
    content: '';
    position: absolute;
    left: 40px;
    bottom: 34px;
    width: 25px;
    border-radius: 4px;
    background-color: #4b587857;
    height: 2px;
}

.thread-container {
    background-color: #221f39;
    border-radius: 6px;
    padding: 8px 12px;
}

.encryption-key-container {
    background-color: #221f39;
    border-radius: 6px;
    padding: 12px;
    margin: 4px 0;
    border: 1px solid var(--red);
    color: var(--red);
    /* font-size: 14px; */
    font-weight: 500;
    font-style: italic;
    max-width: max-content;

    gap: 5px;
    margin-top: 4px;
    position: relative;
    word-wrap: normal;
    margin-right: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    /* word-break: break-all; */
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.error-circle {
    height: 16px;
    width: 16px;
    cursor: pointer;
}

@media screen and (max-width: 480px) {
    .message-line-container {
        padding: 8px 12px;
    }

    .owner-image,
    .owner-image img {
        width: 36px;
        height: 36px;
    }

    .dot {
        font-size: 12px;
    }
}