.container {
    border-radius: 8px;
    background: var(--left-sidebar);
    bottom: 0;
    left: 0;
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    position: sticky;
}

.container h2 {
    color: var(--text-3);
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.container p {
    margin: 0;
    color: var(--gray-3);
}

.badge-img {
    width: 15px;
    height: 15px;
    bottom: 0;
    right: 0;
    border: 3px solid var(--left-sidebar);
}

.d-flex {
    display: inline-flex;
    column-gap: 16px;
}

.mic-btn {
    cursor: not-allowed;
    opacity: 0.5;
}

.mic-btn>img,
.settings-btn>img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.3s ease;
}

.badge>img {
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    height: 64px;
    width: 64px;
}

.flex-start {
    margin-inline-start: 10px;
    margin-inline-end: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 40%;
}

.mute-mic {
    width: 2px;
    height: 100%;
    background: var(--red);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: rotate(45deg);
    margin: auto;
    border-radius: 20%;
    pointer-events: none;
    opacity: 0.5;
}

.relative {
    position: relative;
}

/* tooltip */

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: #ddd;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 4px 12px;

    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    bottom: 120%;
    transform: translateX(-50%);
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

@media screen and (max-width: 480px) {
    .badge>img {
        height: 48px;
        width: 48px;
    }
    .container h2 { 
        font-size: 14px;
    }
}