.media-container {
    >* {
        height: fit-content;
        width: fit-content;
        max-width: 300px;
    }
}

.server-name {
    margin: 8px 0;
}

.modal {
    max-height: 90vh;
    overflow-y: scroll;
}

.error {
    font-size: 12px;
    color: var(--red);
}

.continer>form>p,
.continer>form>label {
    padding: 24px 0 8px 0;
    margin: 0;
    color: var(--text-2);
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.server-img {
    width: 77px;
    height: 77px;
}

.text-area,
.server-name {
    border-radius: 8px;
    background-color: var(--main-card-blue);
    color: var(--white-20);
    font-size: 14px;
    font-weight: 400;
}

.text-area {
    resize: vertical;
    max-height: 100px;
    min-height: 30px;
}

.btn {
    margin-top: auto;
    margin-left: auto;
    border-radius: 4px;
    min-width: 144px;
    height: 40px;
    padding: 8px;
}

.form-in-container {}

.form-wrap {
    display: flex;
    flex-direction: column;
    gap: 56px;
}

.server-banner {
    max-width: 547px;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
}

.server-banner img {
    object-fit: cover;
}

.media {
    aspect-ratio: 2.7;
}

.profile-img {
    position: absolute;
    left: 12px;
    top: calc(100% - 40px);
    margin-bottom: 0;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column dense;
}

.chat-img {
    grid-row: span 2;
}

@media screen and (max-width: 480px) {
    .form-in-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .media-container>div>div {
        height: 120px;
    }

    .text-area {
        min-height: 50px;
        max-height: 220px;
    }
}