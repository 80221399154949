.contentWrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
}

.headingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 10px 0;
    text-align: center;
    max-width: 800px;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    flex: 0.8;
    width: 400px;
}

.resetPass {
    display: flex;
    align-self: flex-end;
    font-size: 12px;
    margin: 8px 0px;
    color: var(--text-heading);
    cursor: pointer;
}

.version {
    font-size: 12px;
    margin: 8px 0px;
    color: var(--text-heading);
    text-align: center;
}

.main-img {
    height: auto;
    max-height: 312px;
}

.header-img {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.title {
    position: absolute;
    inset: 0;
    height: fit-content;
    width: fit-content;
    margin: auto;
    color: var(--covchat-title);
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.464px;
}

.primaryHeading {
    color: var(--white);
    text-shadow: 0px 16px 16px var(--title-shadow);
    font-size: 32px;
    font-weight: 600;
    margin-top: 0;
}

.sub-heading {
    color: var(--white-50);
}

.language-select {
    padding: 0px !important;
    height: 20px;
    border: 1px solid rgb(255, 255, 255, 0.3);
    margin-left: 4px;
    font-size: 10px;
    width: 64px;
    border-radius: 4px;
    cursor: pointer;
}

.language-span {
    font-size: 10px;
    color: rgb(255, 255, 255, 0.5);
}

.language-wrap {
    position: fixed;
    right: 12px;
    top: 12px;
}

.lang-dropdown {
    position: relative;
    width: max-content;
    bottom: unset !important;
}

.trigger-wrap {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: var(--dark-input);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 03s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (max-width: 480px) {
    .main-img {
        max-height: 150px;
    }

    .inputWrapper {
        flex: none;
    }

    .contentWrapper {
        justify-content: space-evenly;
    }
}