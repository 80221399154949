.left-side-container {
    /* springProps */
    height: 100%;
}

.left-side-inner-container {
    height: 100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
}

.left-side-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid var(--room-item);
}

.left-side-header-close {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 6px;
}

.left-side-header-close:hover {
    cursor: pointer;
    border: 1px solid var(--room-item);
}

/* ******************************* */

.form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    padding-top: 16px;
    /* height: 85%; */
    padding-right: 8px;
}

.input-area {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    gap: 8px;
}

.profile-pic {
    margin: auto;
    width: fit-content;
    margin-bottom: 16px;
}

.media-img {
    width: 50px;
    height: 50px;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.appButton {
    width: 100px !important;
    margin-top: auto;
    /* margin-bottom: 56px; */
}

.topContainer {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.logo {
    height: 64px;
    background-color: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    line-height: none;
}

.title {
    font-size: 20px;
}

.name-username {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0px;
    justify-content: space-between;
}

.external-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.appBtnWrap {
    display: flex;
    justify-content: end;
    padding-block: 20px;
}

@media screen and (max-width: 480px) {
    .name-username {
        display: block;
    }

    .left-side-header h2 {
        margin: 0;
    }

    .topContainer {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .logo {
        width: 100%;
        max-height: 150px;
        object-fit: contain;
        height: auto;
    }

    .title {
        font-size: 1rem;
    }
}