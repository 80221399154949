.slick-track,
.slick-list {
    height: 100%;
}

.slick-dots {
    display: flex !important;
    justify-content: center;
    gap: 35px;
}

.slick-dots .slick-active img {
    transform: scale(1.14);
}

.slick-list {
    height: 100% !important;
}

.slick-slide>div {
    height: 100%;
}

@media screen and (max-width: 480px) {
    .slick-dots li {
        width: 30px;
        height: 30px;
    }

    .slick-dots {
        top: calc(100% - 50px);
        z-index: 3;
        gap: 8px;
        justify-content: flex-start;
    }

}