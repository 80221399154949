.action-container {
    /* background-color: var(--dark-blue-2); */
    border-radius: 4px;
    color: var(--msg-actions);
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.action-container:hover > .action-name-container {
    visibility: visible;
}

.action-name-container {
    visibility: hidden;
    position: absolute;
    bottom: -20px;
    align-items: center;
    display: flex;
}

.action-name {
    background-color: var(--dark-blue-2);
    border-radius: 2px;
    color: var(--msg-actions);
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    padding: 2px 4px;
}

.action-container > img {
    max-width: 16px;
    max-height: 16px;
}
.triangle {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 3px 5.2px 3px;
    border-color: transparent transparent var(--dark-blue-2) transparent;
    transform: rotate(0deg);
    position: absolute;
    top: 5px;
    right: 40%;
    /* left: 45%; */
    transform: translateY(-10px);
    z-index: 1;
}
