.dl-button {
    cursor: pointer !important;
    display: flex !important;
    justify-content: flex-start ;
    align-items: center;
    color: var(--title-shadow) !important;
    gap: 4px;
    width: fit-content;
    position: relative;
    overflow: hidden;
    user-select: none;
    -webkit-user-drag: none;
    width: fit-content;
    flex-basis: 24px;
}

.label {
    height: 15px !important;
    overflow: hidden !important;
}

.icon path {
    fill: #D1D0E1;
}

.icon-save,
.icon-save path {
    fill: transparent;
    stroke: #D1D0E1;
    transition: all .2s ease;
}

.icone-save.saved,
.icon-save.saved path {
    fill: #D1D0E1;
}

.disable-link {
    pointer-events: none;
    opacity: 0.7;
}