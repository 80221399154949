.server-Banner-container {
    width: calc(100% - 20px);
    position: relative;
    height: 120px;
    border-radius: 8px;
    color: var(--keep-white);
    min-height: 100px;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 auto;
    background-color: var(--covchat-title);
    background-repeat: no-repeat;
    /* this will prevent the banner from changing its height is the parent was filled  */
}

.people-counter {
    padding: 4px;
    border-radius: 8px;
    background: var(--blur-blue);
    backdrop-filter: blur(2px);
    min-width: 65px;
    gap: 8px;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    grid-template-columns: auto auto;
}

.people-counter > div {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 4px;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: var(--green);
    position: relative;
    margin: auto auto auto auto;
}

.options-icon {
    cursor: pointer;
    padding: 2px;
    border-radius: 4px;
    background: var(--blur-blue);
    backdrop-filter: blur(2px);
}

.serverName {
    margin: 0;
    width: 100%;
}

.d-start {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    /* position: relative; */
}

.server-icon {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 8px;
}

.sync-messages {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 0;
    gap: 8px;
    margin-bottom: -5px;
}

.sync-messages p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.spinner {
    animation: spin;
    animation-timing-function: linear;
    animation-duration: 600ms;
    animation-iteration-count: infinite;
}

.d-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
