.d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.btn {
    width: fit-content;
    min-width: 120px;
    font-size: 14px;
    min-height: auto;
}

.modal {
    min-width: 360px;
}

.input-field {
    background-color: var(--main-card-blue);
    color: var(--white-20);
}