.close-container {
    margin: auto;
    width: 50px;
    height: 50px;
    position: absolute;
    right: -63px;
    top: -53px;
    cursor: pointer;
    user-select: none;
}

.leftright {
    height: 2px;
    width: 50px;
    position: absolute;
    margin-top: 14px;
    background-color: var(--white);
    border-radius: 8px;
    transform: rotate(45deg);
    transition: all 0.3s ease-in;
}

.rightleft {
    height: 2px;
    width: 50px;
    position: absolute;
    margin-top: 14px;
    background-color: var(--white);
    border-radius: 8px;
    transform: rotate(-45deg);
    transition: all 0.3s ease-in;
}

.close {
    user-select: none;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all 0.3s ease-in;
    color: #f25c66;
    opacity: 0;
    margin: 40px auto 0 auto;
    text-align: center;
    position: absolute;
}

.close-container:hover .leftright {
    transform: rotate(-45deg);
    background-color: #f25c66;
}

.close-container:hover .rightleft {
    transform: rotate(45deg);
    background-color: #f25c66;
}

.close-container:hover label {
    opacity: 1;
}

@media screen and (max-width: 480px) {
    .close-container {
        right: 0;
        top: 0;
        height: 30px;
        width: 30px;
        z-index: 10000000;
    }

    .leftright {
        width: 30px;
    }

    .rightleft {
        width: 30px;
    }
}