.container {
    width: 100%;
    overflow: hidden auto;
    height: calc(100vh - 214px);
    box-sizing: border-box;
}

.status-container {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--dark-input);
    border-radius: 8px;
    padding: 8px;
    gap: 8px;
    margin-top: 8px;
}

.status-container-row {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    cursor: pointer;
    border-radius: 8px;

}

.status-container-row:hover {
    background: var(--bright-hr);
}

.inputs_container {
    width: 95%;
    padding: 24px;
    padding-top: 96px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 105px;
    row-gap: 24px;
}

.input_col {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.banner {
    height: 190px;
    background-color: var(--text-2);
    width: 100%;
}

.profile_pic {
    transform: translateY(0%);
    margin: 0px 24px;
    position: relative;
    bottom: -50%;
}

.text-area {
    background-color: var(--dark-input);
    font-family: Inter;
    height: 100px;
    border: 0;
    color: var(--text-heading);
    font-size: 16px;
    resize: none;
    width: 100%;
}

.appButton {
    width: 100px !important;
    margin-top: 48px;
}

.media-img {
    width: 160px;
    height: 160px;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 480px) {
    .banner {
        height: 100px;
    }

    .media-img {
        width: 70px;
        height: 70px;
    }

    .profile_pic {
        bottom: -63%;
    }

    .inputs_container {
        grid-template-columns: 1fr;
        box-sizing: border-box;
        padding: 12px;
        padding-top: 50px;
        width: 100%;
        font-size: 12px;
        row-gap: 12px;
    }

    .inputs_container input {
        font-size: 13px;
    }

    .input_col {
        gap: 12px;
    }
}