.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-size: 22px;
    font-weight: 600;
}

.btn-wrap {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    margin-top: 16px;
    justify-content: space-between;
}

.btn-wrap button {
    min-width: unset;
    flex: 1 0 calc(50% - 8px);
}

.cancel {
    background-color: transparent !important;
}

.modal-header {
    font-size: 14px;
    font-weight: 600;
    background-color: #100D26;
    border-bottom: 1px solid #2F2B4A;
    padding: 8px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.modal-header>img {
    width: 14px !important;
    height: 14px !important;
}

.p {
    color: #7277AE;
    font-weight: 400;
    font-size: 17px;
    user-select: none;
    margin-bottom: 50px;
}

.body {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: 250px;
    min-height: 100px;
    text-align: center;
    align-items: center;
}