.go-page-input {
    all: unset;
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    width: 30px;
    border-radius: 6px;
    padding: 4px;
    font-size: 12px;
    text-align: center;
}

.go-page-input:focus {
    border: 1.5px solid rgba(255, 255, 255, 0.2);
}

.main-container {
    display: flex;
    align-items: center;
    gap: 4px;
}

.arrow-area {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-left: auto;
    gap: 8px;
}

.arrow-area > img {
    height: 16px;
    width: 16px;
}

.pagination-container {
    display: flex;
    gap: 4px;
    padding: 0px;
    justify-content: space-between;
}

.pagination-number-area {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.pagination-number-container {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    opacity: 0.5;
    min-width: 24px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.pagination-number-container:hover {
    background: var(--chat-background);
    opacity: 1;
}

.pagination-arrow-container {
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.pagination-arrow-container:hover {
    background: var(--chat-background);
    opacity: 1;
}

.pagination-arrow-container > img {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    pointer-events: none;
}

.page-input-area {
    display: flex;
    gap: 8px;
    align-items: center;
}

.no-page-label {
    color: red;
    opacity: 0.7;
    font-size: 12px;
}
