.container {
    flex-direction: column;
    display: flex;
    padding: 12px;
    background-color: var(--dark-input);
    border-radius: 8px;
    margin: 8px 0;
    box-sizing: border-box;
}

.error {
    border: 1px solid var(--red);
}

.errorText {
    font-size: 10px;
    color: var(--red);
    margin-bottom: 8px;
}

.success {
    border: 1px solid var(--green);
}

.successText {
    font-size: 10px;
    color: var(--green);
    margin-bottom: 8px;
}

.labelHeading {
    font-size: 14px;
    color: var(--text-heading);
    margin-bottom: 8px;
}

.inputWrapper {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
}

.leftIcon {
    max-width: 25px;
    max-height: 25px;
    margin-right: 8px;
}

.input {
    font-family: inherit;
    background-color: transparent;
    border: 0;
    color: var(--text-secondary);
    font-size: 16px;
    display: flex;
    flex: 1;
    height: 24px;
}

.input:-internal-autofill-selected {
    background-color: transparent !important;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus {
    border: none;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    box-shadow: 0 0 0px 1000px transparent inset;
    color: var(--text-secondary) !important;
    -webkit-text-fill-color: var(--text-secondary) !important;
    transition: background-color 5000s ease-in-out 0s;
}

.max-length {
    align-self: flex-end;
    font-size: 12px;
    color: var(--text-secondary);
    margin-top: 8px;
}

::placeholder {
    color: var(--text-secondary);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--text-secondary);
}