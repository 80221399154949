.reply-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px;
    position: absolute;
    backdrop-filter: blur(10px);
    background-color: var(--main-bg-blue);
    color: var(--text-2);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 5px;
    min-height: 30px;
    bottom: 88px;
    z-index: 11;
    width: calc(100% - 50px);
}

.reply-container::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: var(--blue-primary);
}

.reply-container p {
    margin: 0;
}

.reply-container p.italic {
    color: var(--blue-metal);
    text-transform: capitalize;
    font-style: italic;
    line-height: 18px;
    margin-inline-end: 12px;
}

.d-inline-f {
    display: inline-flex;
}

.d-flex {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close {
    position: absolute;
    right: 12px;
    top: 12px;
    bottom: auto;
    margin: auto;
    cursor: pointer;
}

.color-span {
    background-color: var(--text-2);
    color: var(--main-bg-blue);
    padding: 4px;
    border-radius: 4px;
    font-size: 10px;
    opacity: 0.8;
}

.reply-media {
    opacity: 0.6;
    cursor: default;
}