.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 80vh;
}

.header {
    padding: 16px;
    /* display: flex;
    flex: 1; */
    flex-direction: column;
}

.search-row {
    display: flex;
    /* flex: 1; */
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
}

.search-row > .text-input {
    margin-right: 16px;
}

.filter-row {
    display: flex;
    align-items: center;
    gap: 40px;
}

.backdrop {
    background: rgba(0, 0, 0, 0.5);
    z-index: 5;
    position: absolute;
}
