.tabs-container {
    display: flex;
    position: relative;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    background-color: #1C183C;
}

.tab {
    padding: 10px;
    cursor: pointer;
    box-sizing: border-box;
    text-wrap: nowrap;
    color: #586C9D;
    font-size: 14px;
    transition: all 0.3s ease;
}

.tab.active {
    color: #ffffff;
}

.index-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: #E4E5F1;
    transition: transform 0.3s ease, width 0.3s ease;
}

.tab:hover {
    color: #ffffff;
}

.tab+.tab {
    margin-left: 5px;
}