.content {
    gap: 5px;
    margin-top: 4px;
    position: relative;
    word-wrap: normal;
    margin-right: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    width: 95%;
    word-break: break-all;
    /* white-space: pre-wrap; */
    overflow-wrap: break-word;
}

.replaced-link {
    color: var(--purple-2);
    cursor: pointer;
    text-decoration: underline;
}

.replaced-mention {
    display: inline;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    padding: 2px 3px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--tag-bg-brown);
    color: var(--tag-text);
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    word-break: keep-all;
}

.user-modal {
    position: absolute !important;
    left: 0;
    bottom: calc(100% + 6px) !important;
    width: 230px;
}
