.container {
    padding: 8px 16px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.container:hover {
    background-color: var(--main-card-blue-head);
}

.read {
    background-color: var(--dark-blue-2);
}

.unread {
    opacity: 0.8;
    background-color: var(--main-card-blue-head-opacity);
    color: var(--text-secondary);
}

.title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    flex: 1;
    width: 100%;
    align-items: center;
}

.hashtag-title {
    font-size: 14px;
    color: var(--text-4);
    font-weight: 600;
}

.title-icon-container1 {
    display: flex;
    padding: 2px;
    border-radius: 14px;
    height: 12px;
    width: 12px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.title-icon-container {
    padding: 4px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-transform: capitalize;
}

.title-icon {
    width: 10px;
    height: 10px;
}

.unread-circle {
    display: flex;

    width: 10px;
    height: 10px;
    border-radius: 8px;
    align-self: center;
    right: 10px;
    margin-bottom: 24px;
    background-color: var(--blue-primary);
}

.container-row {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    flex: 1;
}

.userImage {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 12px;
}

.wrapper {
    margin-left: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.name-date-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.emojiContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 16px;
    flex: 1;
    overflow: hidden;
    /* flex-wrap: wrap; */
}

.emojiBox {
    width: 36px;
    height: 24px;
    padding: 0 4px;
    background-color: var(--emoji-bg);
    border: 1px solid var(--text-4);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    margin-right: 4px;
    font-size: 12px;
}

.username {
    color: var(--blue-primary);
    font-size: 14px;
}

.circleBreak {
    width: 5px;
    height: 5px;
    margin: 0 8px;
    border-radius: 5px;
    background-color: var(--message-time);
}

.date {
    color: var(--message-time);
    font-size: 12px;
}

.message-wrapper {
    /* margin-top: 4px; */
    display: flex;
}

.message {
    line-height: 22px;
}

.tagText {
    background-color: var(--tag-bg-brown);
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    margin: 0 8px;
    width: fit-content;
    color: var(--tag-text);
    font-size: 12px;
}

.imageWrapper {
    height: 120px;
    border-radius: 8px;
    margin-right: 8px;
    overflow: hidden;
    max-width: 200px;
    min-width: 180px;
    object-fit: cover;
}

.imageContent {
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    position: relative;
}

@media screen and (max-width: 480px) {
    .container {
        padding: 8px;
    }

    .userImage {
        width: 48px;
        height: 48px;
    }

    .hashtag-title {
        overflow: hidden;
        text-wrap: pretty;
        overflow-wrap: break-word;
    }

    .message p {
        margin: 0;
    }

    .container-row {
        gap: 16px;
    }

    .wrapper {
        margin-left: 0;
    }
}