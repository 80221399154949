.container {
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    height: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.heading-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
}

.heading-wrapper>img {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
}

.text-wrapper>h2 {
    margin: 0 0 4px 0;
    font-size: 20px;
}

.text-wrapper>span {
    font-size: 16px;
    color: var(--text-heading);
}

.content-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 32px 0 0;
    overflow: auto;
    height: 90%;
    width: 350px;
}

.content-container::-webkit-scrollbar {
    display: none;
}

.content-container.second {
    overflow-y: auto;
}

.content-container>span {
    font-size: 14px;
}

.search-wrapper {
    width: 100%;
    display: flex;
    align-self: flex-end;
    float: right;
}

.server-list-container {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
}

.server-list-container::-webkit-scrollbar {
    display: none;
}

.server-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    background-color: var(--room-header);
    box-sizing: border-box;
    position: relative;
    border: 2px solid transparent;
    cursor: pointer;
}

.server-column:hover {
    background: rgb(255, 255, 255, 0.1);
}

.server-column.active {
    border: 2px solid var(--blue-primary);
}


.server-column-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 8px;
}

.server-column>img {
    width: 40px;
    border-radius: 8px;
    height: 40px;
    object-fit: cover;
}

.checked-icon {
    width: 24px !important;
    height: 24px !important;
}

.server-sub {
    color: var(--text-secondary);
    size: 14px;
}

.username-wrapper {
    display: flex;
    flex: 1;
    gap: 8px;
}

.selected-server {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.selected-server>span {
    font-size: 12px;
}

.button-wrapper {
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
    background-color: var(--room-header);
    margin-top: auto;
    margin-bottom: 0;
}

.form-success {
    box-sizing: border-box;
    border: 1px solid var(--green);
    border-radius: 8px;
    background-color: var(--green-5);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.form-success>span {
    font-size: 14px;
    color: var(--green);
}

.visible {
    /* display: block; */
    visibility: visible;
    opacity: 1;
    padding: 12px;
    margin-top: 16px;
}

.invisible {
    /* height: 0; */
    padding: 0px;
    margin-top: 0px;
    /* display: none; */
    visibility: hidden;
    opacity: 0;
}