.message-container {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    margin-right: 15px;
    margin-top: auto;
    transition: ease-in-out 0.4s;
    scroll-behavior: smooth;
}

.messages-list {
    overflow-y: auto;
    padding-inline-end: 12px;
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
}

.load-more {
    width: 100%;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 10px;
    z-index: 5;
}

.rendered-message-list:focus-visible {
    outline: none !important;
}

.message-item-div {
    transition: ease-in 0.2s;
}

/* Define the initial state of the element */
.fade-entering {
    opacity: 0;
    transform: translateY(-20px);
    /* Optional: Slide up as it fades in */
    transition: opacity 300ms ease, transform 300ms ease;
    /* Define transition properties */
}

/* Define the final state of the element */
.fade-entered {
    opacity: 1;
    transform: translateY(0);
    /* Optional: Reset position */
}

/* Define the initial state of the element when exiting */
.fade-exiting {
    opacity: 1;
    transform: translateY(0);
    /* Optional: Reset position */
    transition: opacity 300ms ease, transform 300ms ease;
    /* Define transition properties */
}

/* Define the final state of the element when exiting */
.fade-exited {
    opacity: 0;
    transform: translateY(20px);
    /* Optional: Slide down as it fades out */
}

.float-scroll-button {
    position: sticky;
    z-index: 9;
    right: 4px;
    margin-left: auto;
    bottom: 4px;
    background-color: var(--blue-primary);
    height: 32px;
    width: 32px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.float-scroll-button img {
    width: 12px;
    margin-top: 4px;
    filter: brightness(0) invert(1);
}

@media screen and (max-width: 480px) {
    .message-container {
        margin-right: 2px;
    }
}