.thread-container {
    flex: 0 0 calc(100% - 100px);
    background-color: #4b587857;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    margin: 16px 20px 0 57px;
    backdrop-filter: blur(8px) opacity(1);
}

.d-flex {
    display: flex;
    gap: 8px;
    align-items: center;
}

.d-flex p,
.d-flex span {
    font-size: 11px !important;
    font-weight: 300;
}

.d-flex p {
    font-weight: 400;
}

.justify-start {
    justify-content: flex-start;
}

.justify-between {
    justify-content: space-between;
    margin-bottom: 8px;
}

.img {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    object-fit: cover;
}

.total-num {
    color: var(--blue-1);
    text-decoration: none;
    font-size: 11px;
    font-weight: 600 !important;
    transition: all .2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.thread-container:hover .total-num {
    text-decoration: underline;
    color: var(--status-hide);
}