.container {
    padding: 8px;
    border-radius: 8px;
    /* background-color: var(--main-card-blue); */
    background-color: rgba(100, 100, 100, 0.1);
    color: var(--white);
}

.container h3 {
    color: var(--gray);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.28px;
    margin: 0;
    margin-bottom: 12px;
}

.container > ul {
    gap: 8px;
    overflow: auto;
    max-height: 164px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.icon {
    width: 24px;
    height: 24px;
    margin-inline-start: -5px;
}

.icon-img {
    border-radius: 8px;
}

.name {
    font-size: 12px;
}

.wrap,
.active-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    position: relative;
    margin: 4px 10px 4px 10px;
    height: 27px;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.wrap p,
.active-wrap p {
    margin: 0;
}

.wrap:hover,
.active-wrap:hover {
    background-color: var(--room-item);
}

.active-wrap {
    background-color: var(--room-item);
}

.room-icon {
    margin-left: auto;
    max-width: 12px;
    max-height: 12px;
    opacity: 0;
    transform: translateX(40px);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.3s ease;
}

.user-item {
    margin-top: 8px;
    position: relative;
}

.wrap:hover .room-icon,
.active-wrap:hover .room-icon {
    transform: translateX(-10px);
    opacity: 1;
}

.wrap:hover .room-count-badge,
.active-wrap:hover .room-count-badge {
    transform: translateX(-30px);
}

.delete {
    width: fit-content;
    height: fit-content;
    min-width: 120px;
    margin-left: auto;
    margin-right: 0;
}

.d-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.d-flex h3 {
    margin: 0;
}

.room-count-badge {
    background-color: var(--red);
    right: 0;
    position: relative;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-right: 0px;
}

.hr {
    height: 2px;
    width: calc(100% + 16px);
    margin-left: -8px;
}

.enter {
    opacity: 0;
    transition: all 0.3s ease;
}

.enter-active {
    opacity: 1;
    transition: all 0.3s ease;
}

.active-exit {
    opacity: 0;
    transition: all 0.3s ease;
}
