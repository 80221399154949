.media-container {

    /* background-color: #fff; */
    >* {
        max-height: fit-content;
        width: fit-content;
        max-width: 90%;
    }
}

.container {
    width: 80vw;
    height: 90vh;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: var(--main-bg-blue);
    z-index: 99;
}

.modal-container {
    display: flex;
    /* flex: 1; */
    height: 100%;
}

.container-header {
    background-color: var(--main-card-blue);
}

.wrapper {
    display: flex;
    width: 80%;
    flex-direction: row;
}

.left-container {
    display: flex;
    height: 70vh;
    margin-right: 16px;
    flex: 0.75;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    padding: 0 16px;
}

.right-container {
    /* display: flex; */
    flex: 0.3;
    margin-left: 16px;
    margin-top: 20px;
}

.cancel-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
}

.cancel {
    margin-top: 16px;
}

.button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-top: 20px;
    justify-content: end;
}

.btn-color {
    background-color: var(--red) !important;
}

.right-bottom-text {
    padding: 20px 0;
}

.title-sub-wrapper {
    display: flex;
    flex-direction: column;
    margin: 16px 0px;
    padding: 0px 16px;
}

.title {
    font-size: 22px;
    font-weight: 600;
}

.input-wrap label {
    font-size: 12px !important;
}

.media {
    width: 248px;
    max-width: 100%;
    max-height: 248px;
    aspect-ratio: 1;
    object-fit: contain;
}

.subtitle {
    size: 14px;
    color: var(--text-secondary);
    margin-top: 8px;
}

.desc {
    size: 14px;
    color: var(--text-secondary);
    margin-top: 4px;
}

.pvt-text {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    flex: 70%;
    /* width: 100%; */
}

.pvt-container {
    overflow: hidden;
    display: flex;
    /* max-width: 100%; */
    /* width: 100%; */
    position: relative;
    align-items: center;
    background-color: var(--right-sidebar);
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    /* flex: 1; */
    margin: 16px;
}

.roles-members-container {
    display: flex;
    flex: 1;
    position: relative;
    background-color: var(--chat-background);
    border-radius: 8px;
    padding: 16px;
    /* min-height: 120px; */
    margin-bottom: 24px;
    overflow-y: visible;
}

.roles-members-wrapper {
    display: flex;
    flex: 40%;
    justify-content: space-between;
    margin-right: 20px;
    margin-top: 12px;
    position: relative;
    flex-direction: column;
}

.roles-members-title {
    display: flex;
    flex-direction: column;
    /* flex: 40%; */
    justify-content: space-between;
    margin-bottom: 16px;
}

.roles-members {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    overflow-y: auto;
    max-height: 250px;
    min-height: 150px;
    overflow-x: hidden;
}

.member-permissions-wrapper {
    display: flex;
    flex: 60%;
    flex-direction: column;
}

.permission-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    align-items: center;
    background-color: var(--chat-background);
    border-radius: 8px;
    margin: 12px 0;
}

.add-icon {
    width: 12px;
    height: 12px;
    padding: 4px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
}

.section-label {
    margin-bottom: 10px;
}

.icon {
    width: 24px;
    height: 24px;
    margin-inline-start: -5px;
}

.icon-img {
    border-radius: 8px;
}

.name {
    font-size: 12px;
}

.wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    position: relative;
    margin: 4px 0px 4px 4px;
    height: 27px;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.room-icon {
    margin-left: auto;
    max-width: 10px;
    max-height: 10px;
    opacity: 0;
    transform: translateX(40px);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.3s ease;
}

.wrap:hover .room-icon,
.active-wrap:hover .room-icon {
    transform: translateX(-10px);
    opacity: 1;
}

.left-navbar {
    padding-top: 16px;
    flex: 20%;
    display: flex;
    height: 100%;
    flex-direction: column;
    border-right: 1px solid var(--bright-hr);
    min-width: 190px;
    height: calc(90vh - 150px);
    overflow-y: auto;
}

.navbar-item {
    padding: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.active {
    background-color: var(--room-item);
}

.navbar-icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
}

.right-content {
    /* padding: 16px; */
    width: 100%;
    overflow: hidden;
    height: 100%;
}

.right-container-scroll-area {
    height: calc(90vh - 140px);
    overflow-y: auto;
}

.channel-edit-form-container {
    padding: 0px 16px;
    display: flex;
    gap: 24px;
    width: 100%;
    box-sizing: border-box;
}

@media screen and (max-width: 480px) {
    .container {
        width: 100dvw;
        height: 100dvh;
    }

    .modal-container {
        flex-direction: column;
    }

    .left-navbar {
        flex-direction: row;
    }

    .channel-edit-form-container {
        flex-direction: column;
    }

    .title {
        font-size: 1.1rem;
    }

    .right-container-scroll-area {
        height: calc(100vh - 200px);
    }
}