.main {
    position: relative;
    box-sizing: border-box;
    height: calc(100% - 16px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 1rem;
    background-color: var(--left-sidebar);
    margin: 8px;
    border-radius: 8px;
    width: calc(100dvw - 16px);
}

.main ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    color: var(--purple-2);
    font-size: 0.85rem;
}

.active-tab {
    color: var(--blue-primary);
}

.main ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
}

.main ul li svg {
    width: 1.4rem;
    height: 1.4rem;
}

.main ul li:first-of-type svg path {
    stroke: var(--purple-2);
}

.main li.active-tab:first-of-type svg path {
    stroke: var(--blue-primary);
}

.main ul li:last-of-type svg path {
    fill: var(--purple-2);
}
.main li.active-tab:nth-of-type(2) svg path:first-of-type,
.main li.active-tab:nth-of-type(3) svg g path:last-of-type,
.main li.active-tab:last-of-type svg path {
    fill: var(--blue-primary);
}