.theme-toggler {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding: 2px;
    margin: 16px;
    width: 100%;
    border: 1px solid var(--dark-blue-3);
}

.toggle>li {
    width: 100%;
    padding: 4px 8px;
    border-radius: 3px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indicator {
    background-color: var(--blue-primary);
    width: calc(50% - 4px);
    height: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 3px;
    transform: translateX(0);
    transition: all .3s ease;
}