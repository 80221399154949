.container {
    position: fixed;
    inset: 0;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    background-color: var(--main-bg-blue);
    display: flex;
    flex-direction: column;
}

.top-area {
    width: fit-content;
    margin: auto;
}

.chat-logo-container {
    margin: auto;
    width: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bottom-text {
    font-size: 16px;
    width: fit-content;
    text-align: center;
    margin: 0 auto 24px auto;
    color: var(--text-secondary);
}
