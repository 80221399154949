.version-item {
    background-color: var(--tabs-management-header);
    min-height: 100px;
    max-height: 200px;
    overflow: hidden;
    margin-top: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.version-item-active {
    background-color: var(--tabs-management-header);
    min-height: 100px;
    max-height: 2000px;
    overflow: hidden;
    margin-top: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
}
.right-container {
    justify-content: end;
    display: flex;
}
.cell-container {
    background: var(--status-later);
    width: 6px;
    height: 14px;
    border-radius: 10px;
    margin-left: 4px;
}

.cell-text {
    font-size: 12px;
    font-weight: bold;
    margin-left: 6px;
    text-transform: uppercase;
}
.date-size {
    display: flex;
    width: 300px;
    justify-content: end;
    align-items: center;
}
.line-container {
    height: 10px;
    border: 1px solid var(--text-heading);
}
.date-size-text {
    font-size: 12px;
    color: var(--text-heading);
}
.circle {
    display: flex;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid;
}
.header-name {
    align-items: center;
}
.new-container {
    width: 40px;
    height: 8px;
    padding: 8px;
    background: var(--blue-primary);
    display: flex;
    justify-content: center;
    align-items: center;
}
.version-name-heading {
    color: var(--light-grey);
    font-weight: bold;
    font-size: 16px;
    /* margin-left: 10px; */
}
.description-text {
    font-size: 12px;
}

.text-container {
    display: flex;
    margin-left: 20px;
    /* width: 1000px; */
    /* margin-top: 20px; */
}

.version-type {
    width: 96px;
    height: 24px;
    background: var(--black);
    display: flex;
    align-items: center;
    padding: 4px;
    /* justify-content: center; */
}
.last-update-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.version-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.small-points {
    height: 5px;
    width: 5px;
    border-radius: 100%;
    background: var(--white);
}
.feature-text {
    margin-left: 14px;
    font-size: 14px;
}
.download-icon {
    width: 18px;
    height: 18px;
    margin-left: 5px;
}
.download-container {
    display: flex;
    margin-top: 50px;
    justify-content: space-evenly;
}
.downloading-container {
    display: flex;
    justify-content: space-between;
    width: 90%;
}
.cancel-button {
    background-color: var(--light-to-navy) !important;
    color: var(--black) !important;
}
.progress {
    flex: 1;
}

.download-a-container {
    width: fit-content;
    background: #000 ;
}

@media screen and (max-width: 480px) {
    .cell-container { 
        width: 3px;
    }
    .text-container { 
        margin-left: 0;
    }
    .date-size { 
        width: fit-content;
    }
    .version-item { 
        min-height: 50px;
        padding-bottom: 8px;
    }
    .feature-text { 
        font-size: 8px;
    }
}