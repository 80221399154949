.error-message {
    color: var(--red);
    margin-top: 10px;
    margin-left: 5px;
}

@media screen and (max-width: 480px) {
    .error-message {
        font-size: 8px;
    }
}