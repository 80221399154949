.container {
    border-radius: 8px;
    background-color: #2e3547;
    backdrop-filter: blur(15px);
    font-size: 10px;
    font-weight: 384px;
    position: relative;
    width: 380px;
    height: fit-content;
    padding: 8px;
    cursor: pointer;
    color: var(--white);
}

.container p {
    margin: 0;
}

.close {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    width: 10px;
    height: 10px;
}

.toast-list {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    transition: all 0.3s ease;
}

.icon {
    width: 24px;
    height: 24px;
}

.icon>img {
    border-radius: 4px;
}

.name {
    font-size: 12px;
    font-weight: 500;
}

.oneLine {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}


.d-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.d-flex:first-of-type {
    align-items: center;
    margin-bottom: 8px;
}

.server-icon {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-inline-start: 2.5px;
    margin-inline-end: 2.5px;
}

.clear-btn {
    display: flex;
    width: 120px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    background-color: var(--toast-btn) !important;
    border-radius: 8px;
    color: var(--white);
    font-size: 10px;
    font-weight: 400;
    min-width: fit-content;
    min-height: fit-content;
    height: fit-content;
    margin-left: auto;
}

.toast {
    margin-inline-end: 42px;
}

.toast>li[data-expanded="false"]:not(:nth-child(-n + 3)) {
    opacity: 0;
}

.toaster {
    max-height: 90vh;
    overflow-y: auto;
}