.home-container {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 96px 1fr;
    width: 100vw;
    overflow: hidden;
}

.server-padding-container {
    width: -webkit-fill-available;
    height: var(--height);
    overflow: hidden;
    height: calc(var(--height) - 16px);
    margin: 8px;
    display: inline-flex;
    gap: 8px;
}

.center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

@media screen and (max-width: 480px) {
    .home-container {
        grid-template-columns: 64px 1fr;
        grid-template-rows: 1fr 56px;
    }
}