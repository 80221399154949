.checkbox-indicator {
    width: 40px;
    height: 18px;
    padding: 4px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 16px;
    background-color: var(--room-item);
    transition: all 0.3s ease;
}

.input {
    position: absolute !important;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 3;
    cursor: pointer;
    transition: all 0.3s ease;
}

.input:checked~.check-dot {
    transform: translateX(100%);
    background-color: var(--blue-primary);
}

.check-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--navy-1);
    transform: translateX(0);
    transition: all 0.3s ease;
}