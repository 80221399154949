.container {
    /* position: fixed; */
    inset: 0;
    /* z-index: ; */
    /* width: 100vw; */
    height: var(--height);
    background-color: var(--main-bg-blue);
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.top-area {
    width: fit-content;
    margin: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.chat-logo-container {
    margin: auto;
    max-width: 24px;
    width: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bottom-text {
    font-size: 16px;
    width: fit-content;
    text-align: center;
    margin: 0 auto 24px auto;
    color: var(--text-secondary);
}
