.wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.wrap>p {
    font-size: 15px;
    max-width: 400px;
    text-align: center;
    color: #FFFFFF80;
    font-weight: 400;
}

.select:focus-visible {
    outline: 0;
    border: 0;
}

.select {
    width: 100%;
}

.select>div {
    background-color: var(--room-header);
    border-radius: 8px;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
    border: 1px solid transparent;
    outline: 0;
}

.btn {
    width: 400px;
    min-width: unset;
}