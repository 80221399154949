.main-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 16px;
    z-index: 3;
    background-color: var(--main-card-blue);
    box-sizing: border-box;
}

.main-header::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: var(--bright-hr);
}

.d-flex {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    align-items: center;
}

.close {
    fill: #ffffff;
}

.head {
    font-size: 16px;
    color: var(--text-3);
}

.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    width: 25px;
    height: 25px;
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--bright-hr);
    margin-inline-start: auto;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.options span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: var(--white);
}

.options:hover {
    background-color: var(--hr);
}

.thread-dropdown {
    width: max-content;
    right: -10px;
    left: unset;
    margin: auto;
    top: 40px;
}

.thread-dropdown ul li {
    gap: 12px;
}

.thread-dropdown ul li img {
    max-height: 14px;
}

.relative {
    margin-left: auto;
    position: relative;
}