.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow-x: hidden;
}

.header {
    padding: 16px;
    /* display: flex;
    flex: 1; */
    flex-direction: column;
}

.right-tab {
    max-width: 400px;
    width: 100%;
    background-color: var(--main-card-blue);
    z-index: 999;
    height: 100%;
}

.animate {
    transform: translateX(0);
    display: block;
}

.non-animate {
    display: none;
}
