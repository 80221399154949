.banner-img {
    object-fit: cover;
    height: auto;
    width: 334px;

    max-height: 72px;
}

.profile-img {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
}

.container {
    border-radius: 8px;
    position: fixed;
    right: calc(207px + 8px);
    z-index: 39;
}

.modal {
    background-color: var(--search-bar);
    border-radius: 8px;
    overflow: hidden;
    right: calc(207px + 8px);
    z-index: 34;
}

.modal h2 {
    color: var(--light-to-navy);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 2px;
}

.modal p {
    color: var(--gray-3);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.d-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.server-badge {
    width: 32px;
    height: 32px;
}

.channelImg {
    border-radius: 12px;
    object-fit: cover;
}

.padding-container {
    padding: 16px;
    padding-top: 0;
    margin-top: -35px;
}

.roles-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: auto;
    flex: 1;
    max-width: 300px;
    gap: 8px 0;
}

.badgeClass {
    background-color: var(--darkNavy-to-lightNavy);
    color: var(--light-blue-1);
    border-radius: 4px;
    padding: 8px;
    padding-left: 24px;
    position: relative;
    font-size: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;
}

.badgeClass:hover {
    background-color: var(--dark-blue-2);
}

.badgeClass:hover img {
    background-color: var(--dark-blue-2);
    visibility: visible;
}

.badgeClass img {
    position: absolute;
    visibility: hidden;
    left: 6px;
    width: 12px;
}

.badge {
    content: '';
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--blue-primary);
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.circle {
    /* position: absolute; */
    /* left: 8px; */
    /* margin: auto; */
    /* background-color: var(--blue-primary); */
    margin-right: 16px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.addClass {
    background-color: var(--darkNavy-to-lightNavy);
    color: var(--light-blue-1);
    border-radius: 4px;
    padding: 10px;
    position: relative;
    font-size: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.addClass img {
    width: 10.5px;
}

.roles-modal-container {
    display: flex;
    flex-direction: column;
    max-width: max-content;
    min-width: 250px;
    /* padding-top: 8px; */
    left: 50px;
    top: 50px;
    min-height: 200px;
    /* position: absolute; */
    z-index: 99;
    padding: 0;
    color: white;
}

.roles-list-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
}

.roles-list-item {
    padding: 8px;
    font-size: 14px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.roles-list-item:hover {
    background-color: var(--dark-blue-2);
}

.editIconContainer {
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.editIconLabelContainer {
    display: flex;
    align-items: center;
    display: none;
    position: absolute;
    width: fit-content;
    right: 56px;
    z-index: 0;
}

.editIconLabel {
    color: rgb(255, 255, 255, 0.5);
    background-color: #636175;
    padding: 0px 8px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
    font-size: 12px;
    line-height: 0px;
}

.editIconLabelTriangle {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 16px 0 16px 16px;
    border-color: transparent transparent transparent #636175;
    transform: rotate(0deg);
}

.editIcon {
    height: 20px;
    width: 20px;
    border: 2px solid rgb(255, 255, 255, 0.2);
    padding: 6px;
    border-radius: 8px;
    transition: all 0.1s ease;
    cursor: pointer;
}

.editIcon:hover {
    background-color: rgb(255, 255, 255, 0.1);
    border: 2px solid rgb(255, 255, 255, 0.5);
}

.editIconContainer:hover .editIconLabelContainer {
    display: flex;
}

.closeIcon {
    height: 16px;
    width: 16px;
    border: 2px solid rgb(255, 255, 255, 0.2);
    padding: 8px;
    border-radius: 8px;
    transition: all 0.1s ease;
    cursor: pointer;
}

.closeIcon:hover {
    background-color: rgba(255, 0, 0, 0.2);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.tickIcon {
    height: 16px;
    width: 16px;
    border: 2px solid rgb(255, 255, 255, 0.2);
    padding: 8px;
    border-radius: 8px;
    transition: all 0.1s ease;
    cursor: pointer;
    /* filter: brightness(5); */
}

.tickIcon:hover {
    background-color: rgb(0, 255, 0, 0.2);
    border: 2px solid rgba(0, 255, 0, 0.5);
}

.user-modal-edit-input {
    all: unset;
    width: 100%;
}

.user-modal-edit-input:focus {
    border-bottom: 2px solid rgb(255, 255, 255, 1) !important;
    color: white;
}

/* Özel bir CSS sınıfı kullanma örneği */
.editInput-disabled-style {
    border-bottom: transparent;
}

.editInput-enabled-style {
    padding: 10px 0px;
    border-bottom: 2px solid rgb(255, 255, 255, 0.2);
}

.media-container {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
}

@media screen and (max-width: 480px) {
    .container {
        right: 8px !important;
        overflow: hidden;
    }
    
    .banner-img {
        width: 100%;
        max-height: 100px;
    }
}