.modal {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 270px;
    z-index: 3;
    border-radius: 16px;
    padding: 8px;
    min-width: 200px;
    margin: auto;
    background-color: var(--room-item);
}

.modal > ul {
    max-height: 300px;
    overflow-y: auto;
    margin-top: 8px;
}

.modal > ul > div {
    margin-bottom: 4px;
}

.d-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.badge {
    background-color: var(--room-item);
    position: relative;
    margin: auto;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 10px;
    color: var(--date-color);
}

.icon > img {
    width: 32px;
    height: 32px;
    border-radius: 12px;
}

.d-flex p {
    margin: 0;
    font-size: 13px;
    padding-top: 8px;
}

.select-btn {
    position: relative;
    margin: auto;
    margin-right: 0;
}
.select-btn > input {
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.plus-btn {
    position: relative;
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: var(--white);
    transition: all 0.3s ease;
}

.plus-btn::after {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    width: 8px;
    height: 2px;
    background-color: var(--purple-2);
    border-radius: 12px;
    transition: all 0.3s ease;
}

.plus-btn::before {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    width: 2px;
    height: 8px;
    background-color: var(--purple-2);
    border-radius: 12px;
}

.search-input {
    background-color: var(--dark-blue-2);
    color: var(--gray-5);
}

.appButton {
    max-width: 200px;
    min-width: 100%;
    padding: 10px;
    height: auto;
    border-radius: 6px;
    font-size: 14px;
    margin: auto;
    margin-top: 12px;
}

.select {
    margin-left: 0px;
}

.select:checked + .plus-btn {
    background-color: var(--blue-primary);
}
.select:checked + .plus-btn::after,
.select:checked + .plus-btn::before {
    background-color: var(--white);
}
