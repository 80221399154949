.tree-dots-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
}

.triangle {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 12px 20.8px 12px;
    border-color: transparent transparent var(--dark-blue-3) transparent;
    transform: rotate(0deg);
    position: absolute;
    top: 0px;
    right: 12%;
    transform: translateY(-10px);
    z-index: -1;
}

.edit-image-popup-container {
    position: absolute;
    z-index: 9;
    top: 0;
    right: -12px;
    transform: translateY(20px);
    background-color: transparent;
}

.edit-image-popup {
    background: var(--dark-blue-3);
    min-width: 150px;
    border-radius: 8px;
    transform: translateY(20px);
}

.edit-image-popup-row {
    display: flex;
    gap: 8px;
    font-size: 14px;
    align-items: center;
    min-height: 40px;
    height: auto;
    padding: 0px 8px;
    background: var(--dark-blue-3);
    border-bottom: 1px solid #615d8f;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    line-height: 0;
    cursor: pointer;
    color: white;
    overflow: hidden;
}

.edit-image-popup-row-label {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.edit-image-popup-row:hover {
    opacity: 0.5;
}

.edit-image-popup-row img {
    filter: brightness(0) invert(1);
}

.edit-image-popup-row:last-child {
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.room-header-notificaiton-container {
    display: flex;
}

.exit-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.relative {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: auto;
}

.badge {
    background-color: #4c496b;
    top: -4px;
    right: -8px;
    color: var(--keep-white);
    font-size: 16px;
    padding: 2px 3px;
    /* width: 20px; */
    width: fit-content;
    border-radius: 8px;
    border: 1px solid #615d8f;
}

.badge-image {
    height: 28px;
}

.chat-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.2;
    background-attachment: fixed;
    overflow: hidden;
}

.chat-bg-default {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-repeat: repeat;
    background-size: contain;
    background-position: center;
    opacity: 0.2;
    background-attachment: fixed;
}

.chat-area-container {
    height: calc(var(--height) - 16px);
    width: auto;
    overflow: hidden;
    width: -webkit-fill-available;
    position: relative;
    border: var(--hr) solid 1px;
    border-radius: 8px;
}

.messages-area-container {
    width: calc(100% - 207px);
    flex: 1;
    display: flex;
    height: 100%;
}

.chatCard {
    width: auto;
    padding: 0px;
    height: 100%;
}

.room-header {
    display: flex;
    align-items: center;
    background-color: var(--room-header);
    padding: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: var(--text-3);
    gap: 12px;
    position: relative;
}

.room-header>img {
    height: 32px;
    width: 32px;
    object-fit: contain;
}

.room-name-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    /* max-width: 70%; */
}

.room-name-header {
    position: relative;
    padding: 0px;
    padding-inline-end: 8px;
    font-size: 22px;
    margin: 0;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--text-3);
    text-transform: capitalize;
    letter-spacing: 0px;
    flex: 1;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.strong-hr {
    background-color: var(--bright-hr);
    margin: 0;
    height: 1px;
}

.hr {
    width: 100%;
    margin-bottom: 16px;
}

.channel-headline {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-bottom: 8px;
    padding-top: 8px;
    max-height: max-content;
    box-sizing: border-box;
}

.channel-img {
    margin-inline-start: 16px;
}

.channel-headline h2 {
    margin-bottom: 0;
}

.channel-headline p {
    margin-top: 8px;
}

.messages-container {
    padding-left: 0;
    display: flex;
    grid-auto-columns: 1fr;
    grid-template-rows: 1fr 64px;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
}

.messages-container:focus-visible {
    outline: 0;
}

.d-flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: calc(100% - 60px);
}

.flex-empty {
    display: flex;
    justify-content: center;
    align-items: center;
}

.messages-area-container {
    display: flex;
    flex-direction: column;
}

.header-img {
    border-radius: 12px;
}

.icon {
    border-radius: 8px;
}

.room-id-header-container {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    letter-spacing: 0px;
    gap: 4px;
    width: fit-content;
}

.room-id-header-container>span {
    font-size: 9px;
}

.delete {
    width: fit-content;
    height: fit-content;
    min-width: 120px;
    margin-left: auto;
    margin-right: 0;
}

.badge {
    background-color: var(--red);
    top: -4px;
    right: -4px;
    color: var(--keep-white);
    font-size: 9px;
    padding: 0 2px;
    min-width: 12px;
    max-width: 22px;
    width: fit-content;
    border-radius: 12px;
    border: 0;
}

.areaIconsItem {
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
}

.areaIconsItem:hover {
    background-color: rgb(255, 255, 255, 0.2);
}

.areaIconsContainer {
    display: flex;
    gap: 8px;
    position: relative;
    z-index: 1;
    align-items: center;
    padding-right: 16px;
}

.new-chat-input {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.wrap-empty {
    margin: auto;
    text-align: center;
}

.wrap-empty img {
    max-width: 270px;
    height: auto;
    object-fit: cover;
}

.wrap-empty p,
.wrap-empty h1 {
    background: linear-gradient(92.22deg, #7277AE 29.76%, #ACAFD4 70.72%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.header-inner-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header-inner-2 {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin-right: 16px;
    text-overflow: ellipsis;
}

@media screen and (max-width: 480px) {
    .backward {
        align-self: flex-start;
    }

    .header-inner-1 {
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .chat-area-container {
        height: calc(var(--height) - 70px);
    }

    .room-name-header {
        font-size: 16px;
    }

    .header-img {
        display: none;
    }

    .areaIconsContainer img,
    .areaIconsContainer svg {
        width: 16px;
        height: 16px;
    }

    .areaIconsItem {
        width: 16px;
        height: 16px;
        padding: 1px;
    }

    .areaIconsContainer {
        padding-inline-end: 0;
        width: 100%;
    }

    .copiedText {
        display: none;
    }

    .d-flex {
        height: calc(100vh - 147px);
        height: calc(100dvh - 147px);
    }

    .room-header>img {
        object-fit: cover;
    }

    .room-id-header-container {
        padding-inline: 0;
    }
}