.header-container {
    background-color: var(--tabs-management-header);
    margin: 16px 0 8px 0;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 56px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.badge {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    background-color: var(--red);
    position: absolute;
    left: -7px;
    top: 0;
    font-size: 8px;
}

.header-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.filter-item {
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 40px;
    width: 100px;
    margin-right: 10px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
}

.filter-item-version {
    /* border-radius: 8px; */
    padding: 8px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40px;
    width: auto;
    margin-right: 10px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.filter-item-version.active {
    border-bottom-color: var(--white);
}

.date-label {
    color: var(--text-heading);
    font-size: 12px;
}

.tab-par {
    color: var(--text-heading);
}

.tab-par.active {
    color: var(--white);
}

.active-backgroud {
    background-color: var(--darkNavy-to-lightNavy);
}

.icons-container {
    display: flex;
    align-items: center;
    gap: 16px;
}

/* ------ */

.filter-icon-container {
    position: relative;
}

.filter-container-img {
    width: 24px;
    cursor: pointer;
}

.filter-container-img:hover {
    width: 24px;
    cursor: pointer;
}

.filter-role-modal {
    position: absolute;
    z-index: 50;
    right: 0;
    width: 200px;
    height: auto;
    padding: 8px;
    background: var(--tabs-management-header);
    border-radius: 8px;
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 50px -12px;
}

.filter-modal-row-container {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}

.filter-role-modal-row {
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center;
    width: 100%;
    padding: 12px 8px;
    border-radius: 8px;
}

/* .filter-role-modal-row:hover {
    opacity: 0.5;
} */

.filter-modal-row-name {
    cursor: pointer;
    display: block;
    height: 100%;
    width: 100%;
}

.filter-role-modal-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-role-modal-btn {
    display: flex;
    justify-content: end;
}

.filter-role-modal-btn>span {
    padding: 12px 8px;
    cursor: pointer;
    color: var(--table-header-color);
    font-weight: 600;
}

.filter-role-modal-btn>span:hover {
    text-decoration: underline;
}

.triangle {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 12px 20.8px 12px;
    border-color: transparent transparent var(--tabs-management-header) transparent;
    transform: rotate(0deg);
    position: absolute;
    top: 0px;
    right: 0;
    transform: translateY(-10px);
    z-index: -1;
}


@media screen and (max-width: 480px) {
    .filter-item {
        width: fit-content;
        font-size: 12px;
        height: fit-content;
        min-height: unset;
    }

    .header-container {
        flex-direction: column;
        align-items: center;
        height: fit-content;
        gap: 8px;
        height: fit-content;
    }

    .header-wrapper {
        margin-inline-end: auto;
    }

    .icons-container {
        margin-inline-start: auto;
    }
}