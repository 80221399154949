.modal-container {
    background-color: var(--covchat-title);
    width: 430px;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 50px -12px;
    padding: 16px;
    border-radius: 8px;
    position: absolute;
    top: 54px;
    right: 0;
    z-index: 9;
}

.filter-item-row {}

.filter-item-label {
    color: #bbb9d2;
    margin-bottom: 8px;
    text-transform: capitalize;
}

.filter-item-row {
    width: 100%;
}

.filter-items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.filter-items-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
    width: 100%;
}

.filter-items-scroll-area {
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: scroll;
    width: 100%;
    max-height: calc(var(--height) - 400px);
}

.filter-item {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    padding: 8px 16px;
    background: var(--tabs-management-header);
    border-radius: 4px;
}

.filter-item-active {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    padding: 8px 16px;
    background: var(--blue-primary);
    border-radius: 4px;
}

.header-title {
    font-size: 14px;
    font-weight: bold;
    color: var(--text-heading);
}

.header-subtitle {
    cursor: pointer;
    font-size: 12px;
    color: var(--blue-primary);
}

.bottom-buttons-container {
    margin-top: 10%;
}

.filter-container-img {
    height: 32px;
    width: 32px;
    cursor: pointer;
}

@media screen and (max-width: 480px) {
    .modal-container {
        width: 100%;
        max-width: 100vw;
        box-sizing: border-box;
    }

    .filter-items-scroll-area {
        gap: 16px;
        max-height: calc(var(--height) - 400px);
    }
    
}