.wrap {
    background-color: transparent;
    border-radius: 6px;
    display: flex;
    width: 21px;
    height: 21px;
    margin-inline-start: auto;
    margin-left: auto;
    justify-content: center;
    cursor: pointer;
    position: relative;
    align-items: center;
    transition: all .15s ease;
}

.icon {
    position: relative;
    transform: translateY(0);
    transition: all .15s ease;
}

.wrap:hover {
    background-color: #4B476B;
}

.wrap:hover .icon {
    width: 12px;
    height: 12px;
}

.wrap:hover .icon {
    transform: translateY(-3px);
}

.wrap::after {
    content: '';
    height: 1px;
    width: 12px;
    left: 0;
    right: 0;
    bottom: 4px;
    margin: auto;
    position: absolute;
    background-color: #918CBD;
    transform: scaleX(0);
    transition: all .2s cubic-bezier(0.19, 1, 0.22, 1);
}

.wrap:hover::after {
    transform: scaleX(1);
}