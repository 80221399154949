.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 300px;
    padding: 24px;
}

.header {
    font-size: 14px;
    font-weight: 600;

}

.header>img {
    width: 14px !important;
    height: 14px !important;
}

.container p {
    margin: 0;
    color: #7277AE;
    font-size: 16px;

}

.hex-wrap {
    line-height: 16px;
    font-weight: 500;
    color: #7277AE;
    background-color: #100D26;
    border-radius: 9px;
    padding: 16px;
    align-self: stretch;
    outline: 0;
    border: 0;
    text-align: center;
}

.hex-wrap:focus-visible {
    outline: 0;
    border: 0;
}

.btn-wrap {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    margin-top: 16px;
    justify-content: space-between;
}

.btn-wrap button {
    min-width: unset;
    flex: 1 0 calc(50% - 8px);
}

.cancel {
    background-color: transparent !important;
}

.invalid {
    border: 1px solid red !important;
    color: red;
}

.color::-webkit-color-swatch-wrapper {
    display: none;
}

.color {
    align-self: stretch;
    width: 100%;
    border-radius: 6px;
    border: 0;
}