.modal {
    position: fixed;
    z-index: 12121212;
    width: 70vw;
    height: 80vh;
    margin: auto;
    inset: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.backdrop {
    position: fixed;
    z-index: 121212;
    width: 100dvw;
    height: var(--height);
    left: 0;
    top: 0;
    background: #000000d6;
}

.arrow-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 8px;
    border: 0;
    background-color: #ffffff52;
    transition: all 0.3s ease;
}

.arrow-img:hover {
    transform: scale(1.1);
}

.slider-img {
    margin: auto;
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 12px;
    object-fit: contain;
    position: relative;
    z-index: 3;
}

.main-image-wraper {
    margin: auto;
    position: relative;
    display: inline-flex !important;
    height: 100%;
}

.slick-slider {
    width: 100%;
    height: 100%;
    position: relative;
}

.downlaod-icon {
    width: 24px;
}

.tools-bar,
.message-info {
    position: absolute;
    width: fit-content;
    height: 60px;
    top: 103%;
    right: 0;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
}

.message-info {
    top: calc(100% + 8px);
    right: auto;
    left: 0;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--keep-white);
}

.message-info p {
    margin-top: 0;
}

.owner-image>img {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    object-fit: cover;
}

.message {
    font-size: 11px;
    margin-top: 8px;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.next-arrow {
    width: 30px !important;
    transform: rotate(-90deg);
    right: -50px;
    transition: all 0.2s ease;
}

.next-arrow:hover {
    transform: scale(1.3) rotate(-90deg);
}

.prev-arrow {
    width: 30px !important;
    left: -50px;
    transform: rotate(90deg);
    transition: all 0.3s ease;
}

.prev-arrow:hover {
    transform: scale(1.3) rotate(90deg);
}

.copy-icon-container {
    width: fit-content;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
}

.copy-icon-container p {
    font-size: 11px;
}

.copy-icon {
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1);
}

.copied {
    right: -4px;
    bottom: -4px;
    position: absolute;
    opacity: 1;
    width: 10px;
    transition: all 0.3s ease;
}

.copy {
    right: -4px;
    bottom: -4px;
    position: absolute;
    width: 0;
    opacity: 0;
    transition: all 0.3s ease;
}

@media screen and (max-width: 480px) {
    .modal {
        width: 95dvw;
    }

    .arrow-img {
        width: 30px;
        height: 30px;
    }

    .tools-bar {
        top: calc(100% + 8px);
    }

    .next-arrow {
        right: 0;
        top: calc(75%);
        left: 40px;
        margin: auto;
    }

    .prev-arrow {
        left: 0;
        top: calc(75%);
        right: 40px;
        margin: auto;
    }

    .next-arrow:hover {
        transform: scale(1) rotate(-90deg);
    }

    .prev-arrow:hover {
        transform: scale(1) rotate(90deg);
    }

}