.image-container {
    position: relative;
    width: 170px;
}

.copy-button-container {
    cursor: pointer;
    position: absolute;
    background-color: #302C55;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    opacity: 0.93;
    gap: 4px;
    right: 4px;
    padding: 4px;
    top: 4px;
}

.image-container:hover .copy-button-container {
    visibility: visible;
    opacity: 1;
}

.selected-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 360px;
    gap: 10px;
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 13px;
}

.selected-items img,
.selected-items video,
.fileReader,
.others {
    width: 175px;
    height: 100px;
    display: inline-flex;
    overflow: hidden;
    object-fit: cover;
    border-radius: 12px;
}

.fileReader {
    position: relative;
}

.others {
    background-color: var(--room-item);
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
    width: 155px;
    height: 80px;
}

.other-file-container {
    width: auto !important;
    border: 2px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 8px;
    border-radius: 12px;
    height: auto !important;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    padding: 16px;
}

.other-file-container>a {
    width: 100%;
}

.other-file-container-label {
    color: var(--drop-down-item);
    font-size: 16px;
    text-wrap: nowrap;
    line-height: 1;
}

.btnJoin {
    margin-top: 16px;
    min-width: 100%;
    /* height: auto; */
    color: var(--keep-white);
    font-size: 12px;
    font-weight: 500;
}

.embed-a {
    position: relative;
    width: 100%;
    height: 100%;
}

.embed-a-div-container {
    visibility: hidden;
    position: absolute;
    right: 0;
    background-color: var(--main-bg-blue);
    border-bottom-left-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 112px;
    height: 48px;
    gap: 16px;
}

.embed-a-div {
    cursor: pointer;
    background-color: var(--main-bg-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.embed-a-div:hover {
    opacity: 0.5;
}

.fileReader:hover .embed-a-div-container {
    visibility: visible;
}

.overlay {
    position: absolute;
    background-color: #100d2676;
    backdrop-filter: blur(6px);
    align-items: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    right: 0;
    top: 0;
    margin-top: 6px;
    bottom: 0;
    margin: auto;
}

.overlay span {
    color: #dcdbe7f5;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    user-select: none;
    text-shadow: 0 0 2px #ffffff9c;
    transition: all .2s ease;
}

.overlay span:hover {
    text-shadow: 0 0 8px #ffffff9c;
}

.selected-items img {
    height: auto;
    width: calc(100% + 20px);
}

.fit-screen>img,
.fit-screen>video,
.fit-screen>.others,
.fit-screen>.fileReader {
    width: 100%;
}

.mediaContainer>img,
.mediaContainer>video,
.mediaContainer .fileReader,
.mediaContainer .others {
    width: 175px;
    height: 100px;
    display: inline-flex;
    overflow: hidden;
    object-fit: cover;
    border-radius: 12px;
}

.video-wrap {
    position: relative;
    width: 100%;
    height: 100%;

}

.video {
    width: 100%;
    cursor: pointer;
    height: 100%;
    object-fit: cover;
}

.play-icon {
    position: absolute;
    inset: 0;
    margin: auto;
    cursor: pointer;
}

.video-duration {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    width: fit-content;
    height: 16px;
    padding: 3px 6px;
    background-color: var(--white);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--toast-btn);
    font-size: 10px;
    font-weight: 500;
}

.download-items-container {
    width: 100%;
    gap: 10px;
    height: 100%;
    position: relative;
    margin-top: 8px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: dense;
    box-sizing: border-box;
}

.download-file-container {
    padding: 8px;
    border-radius: 8px;
    gap: 8px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* flex: 0 0 calc(24% - 4px); */
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 100% !important;
    flex-wrap: nowrap !important;
    /* min-width: 200px; */
}

.label-name {
    font-size: 12px;
    text-wrap: pretty;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 31px;
}

.label-size {
    padding-top: 6px;
    font-size: 10px;
    color: #7277AE;
    line-height: 12px;
    font-weight: 500;
}

.button-download-container {
    margin-top: auto;
    padding-top: 16px;
    width: 100%;
}

.button-download {
    background-color: #fff;
    width: 100%;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: none;
    border: none;

}

.gallery {
    overflow: hidden;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    max-width: -webkit-fill-available;
    flex: 0 0 calc(24% - 4px);
    min-width: 200px;
    border-radius: 8px;
    max-height: 120px;
    height: min-content;
    transition: all 0.3s ease;
}

.gallery a {
    color: var(--white);
}

.gallery img,
.gallery video,
.gallery div {
    max-width: 220px;
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
    flex-wrap: wrap;
}


.gallery div {
    height: fit-content;
}

.video-wrap {
    background-color: #302C55;
}

.download-bar {
    height: 8px;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0px 0px 6px 6px;
}

.media-loading-container {
    background-color: var(--room-item);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24%;
    height: 120px;
    width: 100%;
    border-radius: 8px;
}

.view-icon {
    width: 20px;
    height: 16px;
    filter: invert(1);
    padding: 4px,
}

.font-9 {
    font-size: 9px;
}

.download-icon {
    width: 24px;
    height: 24px;
}

.mr-auto {
    margin-inline-end: auto;
}

.relative {
    position: relative;
    width: 200px;
}

.audio-controls {
    flex-basis: 100%;
    display: flex !important;
    justify-content: space-between;
    flex-wrap: nowrap !important;
    align-items: center;
    gap: 8px;
}

.file-info-wrap {
    margin-right: auto;
    flex: 1;
}

.icon-wrap {
    height: 16px !important;
    width: 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timer {
    flex: 1 0 40px;
}

.audio-progress>input {
    cursor: pointer !important;
}

.error {
    font-size: 11px;
    color: var(--red);
    border: 1px solid var(--red);
}

.wrap-1 {
    width: 100%;
    height: 100%;
}

.image-container-1 {
    width: 100%;
    height: 100%;
}

.image-container-1>video,
.image-container-1>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.wrap-0 {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background-color: #302C55;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    grid-column: span 1;
}

.save-btn {
    justify-content: flex-end !important;
}

.single {
    grid-row: span 1;
    height: 72px;
}

.double {
    grid-row: span 2;
    height: calc(10px + 144px);
}

.audio-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: start;
    justify-content: space-between;
    gap: 8px;
}

.audio-header p {
    max-height: 16px;
}

.audio-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
}

@media screen and (max-width: 1200px) {

    .download-file-container,
    .gallery {
        flex: 0 0 calc(32% - 1px);
        flex-grow: 1;
    }

    .download-items-container {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media screen and (max-width: 480px) {
    .download-file-container,
    .gallery {
        width: 100% !important;
    }
}

@media screen and (min-width: 1600px) {
    .download-items-container {
        grid-template-columns: repeat(4, 1fr);
    }
}