.ImgButton {
  outline: none;
  border: 0;
  width: fit-content;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  cursor: pointer;
  padding: 0;
}

.ImgButton:focus,
.ImgButton:focus-visible {
  outline: none;
  border: 0;
}

.sendIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  transform: rotate(0deg);
  transition: all 0.4s ease;
}

.ImgButton:disabled {
  background-color: transparent;
  border-color: transparent;
}