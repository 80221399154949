.headingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0;
}
.inputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
}

.qr {
    max-width: 132px;
    margin: 10px 0;
    padding: 5px;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
}

.appButton {
    margin: 16px 0;
}
.contentWrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
}
