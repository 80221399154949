.new-chat-modal {
    max-width: min(450px, 80vw);
}

.list-wrap {
    height: min(460px, 80vh);
    max-height: 80vh;
    overflow-y: auto;
}

.no-results {
    padding: 8px 16px;
}

.body-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    position: relative;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 14px;
    word-break: break-word;
}

.body-item:not(:last-of-type)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: var(--hr);
}

.person-badge {
    width: 34px;
    height: 34px;
    border-radius: 12px;
    object-fit: cover;
    overflow: hidden;
}

.badge {
    background-color: var(--blue-primary);
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 8px;
}

.badge>img {
    width: 10px;
}

.btn {
    margin: 16px;
    margin-top: 0;
    min-width: 273px;
}

.NewMessageInput {
    width: calc(100% - 16px - 24px);
    margin-left: 8px;
    margin-bottom: 8px;
}

@media screen and (max-width: 480px) {
    .new-chat-modal {
        max-width: 100dvw;
    }

    .body-list {
        max-height: calc(100dvh - 177px);
    }
}