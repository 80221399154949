.container {
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.header {
    padding: 16px;
    flex-direction: column;
}

.date-filter-row {
    display: flex;
    align-items: center;
}

.filter-item {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    padding: 8px 16px;
    background: var(--tabs-management-header);
    border-radius: 4px;
}

.pagination {
    gap: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 16px;
}

.filter-item-active {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    padding: 8px 16px;
    background: var(--blue-primary);
    border-radius: 4px;
}

.date-input {
    position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
}

input[type='date'][value='']:not(.has-value):not(:focus) {
    color: transparent;
}

input[type='date'][value='']:not(.has-value):not(:focus):before {
    color: var(--text-secondary);
    content: attr(placeholder);
}

.date-label {
    color: var(--text-heading);
    font-size: 12px;
}

.user-filter {
    padding: 0 20px;
}

.search-input {
    width: fit-content;
    margin-inline-end: auto;
}

.date-input-wrap>div,
.search-input>div {
    padding: 8px 12px;
}

.date-input-wrap input,
.search-input input {
    font-size: 14px;
}

.search-input input {
    width: 250px;
}

.date-input-wrap input {
    width: 100px;
}

.filters-wrap {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

@media screen and (max-width: 480px) {
    .date-input-wrap {
        margin-right: 0;
    }

    .date-filter-row {
        flex-direction: column;
        align-items: stretch;
        box-sizing: border-box;
    }
}