.main-wrapper {
    width: -webkit-fill-available;
    overflow: hidden;
    background-color: var(--right-sidebar);
    border-radius: 8px;
    height: calc(var(--height) - 16px);
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.thread-messages-list {
    padding: 10px 9px 10px 0;
    position: relative;
}

.form-wrapper {
    position: sticky;
    background-color: var(--right-sidebar);
    z-index: 2;
    bottom: 0;
    top: 100%;
}

@media screen and (max-width: 480px) {
    .main-wrapper {
        height: calc(100vh - 72px);
        height: calc(100dvh - 72px);
    }
}