.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    padding: 16px;
    flex-direction: column;
}

.sub-heading {
    color: var(--text-heading);
    font-size: 14px;
    padding: 8px 0;
    display: flex;
}
.api-key-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    gap: 16px;
    /* width: 80%; */
}
