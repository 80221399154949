.areaText {
    margin: 8px 0;
    padding: 12px;
    width: calc(100% - 24px);
    background-color: var(--room-item);
    color: var(--gray-4);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 0;
    border-radius: 4px;
    outline: none;
    font-family: "Inter";
}


.areaText:focus-visible,
.areaText:focus {
    outline: none;
    border: none;
}

.areaText:-webkit-autofill,
.areaText:-webkit-autofill:hover,
.areaText:-webkit-autofill:focus,
.areaText:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--gray-4);
    box-shadow: inset 0 0 20px 20px var(--room-item);
    transition: background-color 5000s ease-in-out 0s;
}