tr:hover .hover-icon-container {
    visibility: visible;
}

/* ------------------------- cell Design ----------------------- */

.header-cell {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header-cell span {
    font-size: 14px;
    color: var(--text-heading);
    margin-right: 8px;
}

.header-cell img {
    width: 8px;
    color: var(--text-heading);
}

.user-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
}

.user-column-text {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.user-column>img {
    width: 40px;
    border-radius: 8px;
    height: 40px;
    object-fit: cover;
}

.user-sub {
    color: var(--text-secondary);
    size: 14px;
}

.email {
    font-size: 14px;
    color: var(--text-heading);
}

.status-wrapper {
    display: flex;
    align-items: center;
}

.status {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin-right: 8px;
}

.groups-wrapper {
    position: relative;
    display: flex;
    flex: 1;
    min-width: 300px;
}

.groups-wrapper .groups-item img {
    width: 32px;
    border-radius: 8px;
    margin-right: 4px;
}

.group-item-container {
    position: relative;
}

.group-name-container {
    visibility: hidden;
}

.group-item-container:hover .group-name-container {
    visibility: visible;
}

.groups-item {
    width: 32px;
    border-radius: 8px;
    margin-right: 4px;
    position: relative;
}

.highlightDiv {
    position: absolute;
    background-color: #000000cc;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    text-align: center;
}

.highlightDiv span {
    vertical-align: -webkit-baseline-middle;
    bottom: 0;
}

.hover-icon-container {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    display: flex;
    height: 100%;
}

.hover-icon-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--room-item);
    height: 100%;
    width: 64px;
    align-items: center;
    cursor: pointer;
}

.hover-icon-item>img {
    height: 16px;
    width: 16px;
}

.hover-icon-item:hover {
    background-color: var(--chat-background);
}

/* .block:hover {
    background-color: var(--red);
} */

.hover-icon-item span {
    font-size: 10px;
    margin-top: 4px;
}

.badgeClass {
    background-color: var(--dark-blue-2);
    color: var(--light-blue-1);
    border-radius: 4px;
    padding: 8px;
    padding-left: 24px;
    position: relative;
    font-size: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;
    overflow: hidden;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: clip;
    box-sizing: content-box;
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    align-items: center;
    font-size: 9px;
}

.badgeClass:hover {
    background-color: var(--dark-blue-2);
}

.badgeClass:hover img {
    background-color: var(--dark-blue-2);
    visibility: visible;
}

.badge {
    content: '';
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--blue-primary);
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.table {
    max-height: calc(100vh - 290px);
    max-height: calc(100dvh - 290px);
    padding-bottom: 12px;
}