.popover-container {
    position: relative;
    display: inline-block;
}

.popover-trigger {
    cursor: pointer;
    display: flex;
}

.popover-content {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    z-index: 100;
    background-color: #181436;
    min-width: 150px;
    width: fit-content;
    border-radius: 8px;
    overflow: hidden;
    transform-origin: right top;
}