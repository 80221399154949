.container {
    bottom: 88px !important;
    top: unset;
}

.list-container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 4px;
    width: 100%;
}

.language-item-row {
    display: flex;
    align-items: center;
    padding: 8px 4px;
    border-radius: 4px;
}

.language-item-row:hover {
    background: rgb(255, 255, 255, 0.1);
}

.languageName {
    margin-left: 4px;
    line-height: 0px;
    font-size: 12px;
}

.languageDescription {
    line-height: 0px;
    font-size: 12px;
    margin-left: 8px;
    color: #a39fc9;
}

label {
    width: 100%;
}

@media screen and (max-width: 480px) {
    .container { 
        bottom: 69px !important;
    }
}