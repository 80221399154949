.relative {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.btns-container {
    display: inline-flex;
    position: absolute;
    right: 12px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    gap: 12px;
}

.modal {
    min-width: 384px;
    max-height: 95vh;
}

.spinner {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    background-color: #ffffff12;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.emoji-btn {
    position: absolute;
    left: 8px;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 3;
}

.NewMessageInput {
    padding-left: 40px;
    border-radius: 8px;
}

.sendBTN {
    min-width: fit-content;
    width: fit-content;
    min-height: fit-content;
    height: fit-content;
    color: var(--keep-white);
    font-size: 14px;
    font-weight: 600;
}

.reply-part {
    position: relative;
    border-radius: 6px;
    background-color: var(--main-card-blue-head-opacity);
    padding: 8px;
    font-size: 11px;
    margin-bottom: 8px;
    color: var(--white-20);
}

.reply-part p {
    margin: 0;
}

.reply-part::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    border-radius: 6px 0 0 6px;
    background-color: var(--status-airplane);
}

.reply-to-media {
    border-radius: 6px;
    background-color: var(--white-20);
    padding: 4px;
    font-size: 10px;
    color: var(--main-card-blue-head-opacity);
}
.reply-part .mt-10 {
    max-width: 330px;
    margin-top: 10px;
}

.upload-container {
    position: absolute;
    left: 0;
    top: 48px;
    background-color: rgba(17, 129, 44, 0.5);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 0px 0px 0px 0px;
    z-index: 5;
    height: 8px;
}

.upload-progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: rgba(41, 204, 57, 0.5);
    z-index: 6;
    height: 100%;
    border-radius: 0px 0px 0px 0px;
}

.upload-info {
    position: relative;
    z-index: 7;
    padding: 0px 4px;
    font-size: 5px;
}
@media screen and (max-width: 480px) { 
    .modal { 
        margin-top: 0;
        max-height: 100vh;
        max-height: 100dvh;
        
    }
}