.relative {
    width: 100%;
    display: flex;
    height: fit-content;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
}

.add {
    width: fit-content;
    display: flex;
    height: fit-content;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--room-item);
    color: var(--tooltip);
    text-transform: capitalize;
    font-size: 10px;
    font-weight: 400;
    margin: auto;
}

.relative::before,
.relative::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: var(--room-item);
    height: 1px;
    width: calc(50% - 28px)
}

.relative::before {
    left: 0;
}

.relative::after {
    right: 0;
}