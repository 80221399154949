.backdrop {
    height: var(--height);
    width: 100vw;
    display: flex;
    position: absolute;
    background-color: var(--blur-blue);
    flex: 1;
    align-items: center;
    justify-content: center;
}

.modalContainer {
    min-width: 360px;
    max-width: 384px;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: var(--main-card-blue);
    align-items: center;
    justify-content: space-between;
    min-height: 50vh;
}
.cancel {
    width: 36px;
    display: flex;
    align-self: flex-end;
}

.icon {
    width: 100px;
    margin-bottom: 8;
}
.buttonWrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 16px;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    text-align: center;
}

.title {
    color: var(--text-primary);
    font-size: 24px;
}

.description {
    color: var(--text-secondary);
    font-size: 16px;
    margin-top: 8px;
    /* max-width: 90%; */
    align-self: center;
    white-space: pre-wrap;
    padding: 0 30px;
}

.spinner {
    margin-bottom: 24px;
}

.onlyText {
    flex: 0.8;
    margin-top: 24px;
}
