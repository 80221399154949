.container {
    bottom: 88px !important;
    top: unset;
    width: 100%;
}

.container>ul>li:nth-last-of-type(2) {
    color: var(--red);
}

@media screen and (max-width: 480px) {
    .container {
        bottom: 69px !important;
    }
}