.badge {
    position: absolute;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 500;
    transition: all 0.3s ease;
}
