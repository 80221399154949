.forward {
    cursor: pointer;
    color: var(--primary-blue);
    font-size: 11px;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-weight: 300;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 8px;
    margin-top: 6px;
}
.forward p {
    opacity: 0.6;
}
.forward:hover .img {
    background-color: var(--blue-1);
}
.img {
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    padding: 4px;
    /* transition: all .3s ease; */
}

.img:hover {
    background-color: var(--blue-1);
}

.forward::after {
    position: absolute;
    content: '';
    left: 0;
    width: 100%;
    height: 1px;
    bottom: 0;
    border-radius: 6px;
    background-color: var(--blue-1);
}