.roomItem,
.unread-channel,
.active-channel {
    cursor: pointer !important;
    color: var(--text-2);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    gap: 8px;
    background-color: var(--main-card-blue);
    padding: 6px;
    position: relative;
    /* transition: all 0.3s ease; */
}

.active-channel>* {
    cursor: pointer !important;
}

.roomItem:focus-visible,
.unread-channel:focus-visible,
.active-channel:focus-visible {
    outline: 0;
}

.unread-channel {
    background-color: var(--red) !important;
    /* padding-inline-start: 36px; */
    /* margin-inline-start: 1px; */
}

.active-channel {
    background-color: var(--room-item) !important;
}

.room-type-icon {
    margin-inline-end: 8px;
    width: 15px;
}

.roomName {
    margin: 0 auto 0 0;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.room-icon {
    /* transform: translateX(120px); */
    cursor: pointer;
    margin: 0;
    width: 24px;
    padding: 1px;
    height: auto;
    opacity: 0;
    border-radius: 4px;
    /* transition: all 0.2s ease 0.1s; */
}

.room-icon:hover {
    background-color: var(--blue-metal);
}

/* .room-icon:nth-of-type(2) {
    transition: all 0.2s ease 0.3s;
}

.room-icon:nth-of-type(3) {
    transition: all 0.2s ease 0.5s;
} */

.roomItem:hover,
.unread-channel:hover,
.active-channel:hover {
    background-color: var(--room-item);
}

.roomItem:hover .room-icon,
.unread-channel:hover .room-icon,
.active-channel:hover .room-icon {
    /* transform: translateX(0); */
    opacity: 1;
}

.room-name-container {
    cursor: default;
    display: inline-flex;
    align-items: center;
    margin-right: auto;
    width: -webkit-fill-available;
}

.room-count-badge {
    right: 0;
    background-color: var(--room-item);
    position: absolute;
    left: -1px;
    top: 0;
    width: 24px;
    border-radius: 8px 0px 0px 8px;
    height: 100%;
    padding: 0 2px;
}

.delete {
    width: fit-content;
    height: fit-content;
    min-width: 120px;
    margin-left: auto;
    margin-right: 0;
}


@media screen and (max-width: 480px) {
    .room-count-badge {
        height: calc(100% + 2px);
        top: -1px;
    }
}