.pvt-container {
    overflow: hidden;
    display: flex;
    position: relative;
    align-items: center;
    background-color: var(--right-sidebar);
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    margin: 16px;
}

.role-conteiner {
    width: 100%;
    display: flex;
    gap: 36px;
    padding: 16px 0px;
}

.role-list-container {
    width: 400px;
}

.role-list-scroll-container {
    height: 400px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    padding-left: 0px;
}

.role-list-row-container {
    cursor: pointer;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}

.role-member-list-container {
    margin-top: 16px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    width: 95%;
    height: 400px;
    overflow-y: auto;
}

.role-member-row-container {
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
}

.role-member-row-container>img {
    height: 36px;
    width: 36px;
    object-fit: cover;
    border-radius: 8px;
}

.room-icon-container {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 100%;
}

.room-icon-role {
    position: relative;
    cursor: pointer;
    margin: 0;
    width: 20px;
    height: 20px;
    padding: 2px;
    height: auto;
    border-radius: 4px;
}

.room-icon-role:hover {
    background-color: var(--blue-metal);
}

@media screen and (max-width: 480px) {
    .pvt-container {
        padding: 8px;
    }

    .role-conteiner {
        padding: 0;
        gap: 12px;
    }

    .role-list-container {
        width: 100%;
    }

    .role-list-row-container {
        padding: 4px 6px;
    }

    .role-member-row-container {
        padding: 8px 0;
        font-size: 10px;
    }

    .role-member-row-container img {
        width: 24px;
        height: 24px;
    }

    .role-member-list-container {
        width: auto;
        max-height: 400px;
        height: fit-content;
    }
}