.ckeditor-wrapper .ck.ck-editor__main>.ck-editor__editable {
    background-color: #1c183c8f;
    outline: none;
    border: 0;
}

.ck-sticky-panel__content {
    border-radius: 8px;
}

.ck.ck-reset_all,
.ck.ck-reset_all * {
    color: #B8C8F0;
}

.ck-editor__main {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    background-color: #1C183C;
    border-radius: 8px;
    border: 0;
}

.ck.ck-toolbar {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.ck-toolbar__items {
    justify-content: space-between;
}

.ck.ck-splitbutton.ck-splitbutton_open>.ck-button:not(.ck-on):not(.ck-disabled):not(:hover),
.ck.ck-splitbutton:hover>.ck-button:not(.ck-on):not(.ck-disabled):not(:hover),
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on,
.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):hover,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
    background-color: #e6e6e64a;
}

.ck.ck-list__item .ck-button:hover:not(.ck-disabled),
.ck.ck-list__item .ck-button.ck-on:hover:not(.ck-disabled),
.ck.ck-list__item .ck-button.ck-on {
    background-color: #e6e6e64a;

}

.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_sw.ck-dropdown__panel-visible,
.ck-reset.ck-list {

    background-color: #1C183C;
    border-radius: 6px;
    border: 1px solid #B8C8F0;

}

.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se.ck-dropdown__panel-visible {
    background-color: #1C183C;
    outline: 0;
    border: 1px solid #B8C8F0;

}

.ck {
    font-family: 'Inter';
}

.ck.ck-reset.ck-list {
    max-height: 350px;
    overflow-y: auto;
}

.ck.ck-character-grid .ck-character-grid__tile:focus:not(.ck-disabled),
.ck.ck-character-grid .ck-character-grid__tile:hover:not(.ck-disabled),
.ck.ck-color-grid__tile:focus:not(.ck-disabled),
.ck.ck-color-grid__tile:hover:not(.ck-disabled) {
    box-shadow: inset 0 0 0 1px var(--ck-color-base-background), 0 0 5px 0px #B8C8F0;
}

.ck .ck-button.ck-color-table__remove-color:not(:focus) {
    border-bottom: 1px solid #B8C8F0;
}

.ck.ck-link-form.ck-responsive-form,
.ck-balloon-rotator__content {
    border-radius: 6px;
    background: #1C183C;
}

.ck.ck-link-form.ck-responsive-form {
    padding: 8px;
}

.ck.ck-input-text {
    background-color: transparent;
}

.ck.ck-input-text,
.ck.ck-input-text:focus {
    border: 1px solid #B8C8F0;
    box-shadow: none;
}

.ck.ck-labeled-field-view.ck-disabled>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label,
.ck.ck-labeled-field-view.ck-labeled-field-view_empty:not(.ck-labeled-field-view_focused)>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label,
.ck.ck-input-text::placeholder {
    color: #B8C8F0;
}

.ck.ck-labeled-field-view>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label {
    background-color: #1C183C;
}

.ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-balloon-panel_arrow_nmw.ck-balloon-panel_visible.ck-balloon-panel_with-arrow {
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid #B8C8F0;
}

.ck.ck-balloon-panel[class*=arrow_n]:before {
    border-bottom-color: #B8C8F0;
}

.ck-restricted-editing_mode_standard.ck.ck-content {
    max-height: calc(100vh - 430px);
}

.ck.ck-toolbar__items {
    justify-content: flex-start;
}

@media screen and (max-width: 480px) {

    .ck.ck-button,
    a.ck.ck-button {
        font-size: 7px;
    }

    .ck-restricted-editing_mode_standard.ck.ck-content {
        max-height: calc(100vh - 294px);
    }
}