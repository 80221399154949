tr:hover .hover-icon-container {
    visibility: visible;
}

/* ------------------------- cell Design ----------------------- */
.statistic-table tr {
    vertical-align: top;
}

.header-cell {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header-cell span {
    font-size: 14px;
    color: var(--text-heading);
    margin-right: 8px;
}

.header-cell img {
    width: 8px;
    color: var(--text-heading);
}

.user-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
}

.user-column-text {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.user-column img {
    width: 40px;
    border-radius: 8px;
}

.user-sub {
    color: var(--text-secondary);
    size: 14px;
}

.status-wrapper {
    display: flex;
    align-items: center;
}

.status {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin-right: 8px;
}

.message-wrapper {
    display: flex;
    padding: 8px 16px;
    border-radius: 8px;
    gap: 8px;
    justify-content: center;
    align-items: center;
    width: max-content;
    background-color: var(--room-item);
}

.reaction-container {
    display: flex;
    width: 127px;
    height: 27px;
    padding: 4px;
    gap: 4px;
    border-radius: 4px;
    background: var(--reaction-on-hover);
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    border: 1px solid var(--room-item);
}

.no-color {
    background-color: '';
}

.main-container {
    max-width: 500px;
    width: calc(3* 135px + 12px + 6px);
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.text {
    font-size: 12px;
}

.text-colored {
    color: var(--text-heading);
}

.table {
    height: calc(100dvh - 358px);
    padding-bottom: 0;
}

@media screen and (max-width: 480px) {
    .user-column img {
        width: 26px;
    }

    .text {
        font-size: 0.8rem;
        margin: 0;
    }

    .reaction-container {
        height: fit-content;
    }

    .message-wrapper {
        padding: 6px 8px;
    }

    .main-container {
        max-width: 200px;
    }
}