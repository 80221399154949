.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 80%;
    padding: 14px;
    height: auto;
    overflow-y: auto;
}

.header {
    padding: 16px;
    flex-direction: column;
}

.sub-heading {
    color: var(--text-heading);
    font-size: 14px;
    padding: 8px 0;
    display: flex;
}

.modal-container {
    display: flex;
    height: 100%;
}

.left-navbar {
    display: flex;
    height: 100%;
    flex-direction: column;
    border-right: 1px solid var(--bright-hr);
    padding: 16px;
}

.navbar-item {
    padding: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
}

.active {
    background-color: var(--room-item);
}

.navbar-icon {
    margin-right: 16px;
}

.back-container {
    border-bottom: 1px solid var(--bright-hr);
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 16px;
}

.back-icon {
    width: 10px;
    margin-right: 8px;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    overflow-y: auto;
    max-height: calc(100vh - 366px);

}

.content h3 {
    margin: 0;
    margin-top: 24px;
    font-weight: 600;
    color: var(--text-heading);
}

.pvt-text {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    flex: 70%;
}

.pvt-container {
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    justify-content: space-between;
}

.permission-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    align-items: center;
    border-radius: 8px;
    margin: 12px 0;
}

.permission-container {
    display: flex;
    flex: 1;
    margin: 16px 0 24px 0;
    flex-direction: column;
}

.desc {
    size: 14px;
    color: var(--text-secondary);
    margin-top: 4px;
}

.line {
    background-color: var(--bright-hr);
    width: 100%;
    border: 0;
    height: 1px;
    margin: 16px 0;
}

.left-nav-list {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 16px;
    position: absolute;
    bottom: 25px;
    right: 37px;
    background: black;
    width: fit-content;
    padding: 8px;
    border-radius: 12px;
    opacity: 1;
    border: 2px solid rgb(255, 255, 255, 0.2);
}

.droppable-list {
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - 285px);
}

.left-main-par {
    font-size: 12px;
    opacity: 0.5;
    margin-bottom: 24px;
}

.add-role-btn {
    margin-top: 16px;
    align-self: flex-end;
}

.color-wrap {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 12px;
    margin: 16px 0;
}

.color-text-wrap {
    width: auto;
    margin-top: 16px;
}

.color-pick-wrap {
    width: 96px;
    margin-top: 16px;
}

@media only screen and (max-width: 720px) {
    .navbar-icon {
        margin-right: 0;
    }
}

@media only screen and (max-width: 480px) {
    .permission-wrapper {
        margin: 0;
    }

    .permission-container {
        gap: 10px;
        padding-bottom: 24px;
    }

    .color-wrap {
        margin: 0px;
    }

    .color-pick-wrap,
    .color-text-wrap {
        margin-top: 4px;
    }

    .left-main-par {
        margin-bottom: 8px;
    }

    .header {
        padding: 8px 4px;
    }

    .content h3 {
        margin-top: 14px;
    }

    .content {
        padding: 8px 4px;
        margin-top: 8px;
    }

    .droppable-list {
        max-height: 200px;
        bottom: 20px;
        overflow-y: auto;
        padding-bottom: 0px;
    }

    .add-role-btn {
        margin-top: 12px;
        align-self: flex-start;
        height: 45px;
    }

    .container,
    .left-navbar {
        padding: 10px;
    }

    .navbar-item {
        padding: 12px 8px;
    }

    .left-nav-list {
        position: sticky;
        width: 100%;
        box-sizing: border-box;
        background-color: var(--main-bg-blue);
        justify-content: space-between;
        bottom: 0;
        box-shadow: 0 0 15px 5px #34314b4d;
    }

    .modal-container {
        flex-direction: column;
    }

    .left-navbar {
        height: fit-content;
        border-bottom: 1px solid var(--bright-hr);
    }
}