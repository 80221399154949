html {
    font-size: 0.9rem;
}

h2 {
    font-weight: 500;
}

.container {
    width: 80vw;
    height: 80vh;
    overflow-y: hidden;
    background-color: var(--main-bg-blue);
}

.modal-container {
    display: flex;
    /* flex: 1; */
    height: 100%;
}

.left-navbar {
    padding-top: 16px;
    flex: 20%;
    display: flex;
    height: 100%;
    flex-direction: column;
    border-right: 1px solid var(--bright-hr);
}

.navbar-item {
    padding: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.navbar-item:hover {
    background-color: var(--room-item);
    transition: background-color 0.1s ease-in-out;
}

.active {
    background-color: var(--room-item);
}

.navbar-icon {
    margin-right: 16px;
}

.right-content {
    display: flex;
    flex: 80%;
    /* overflow-y: scroll; */
}

@media only screen and (max-width: 720px) {
    .left-navbar {
        flex: none;
        width: auto;
    }

    .navbar-icon {
        margin-right: 0;
    }

    .navbar-text {
        display: none;
    }
}


@media screen and (max-width: 480px) {
    .container {
        width: 100dvw;
        height: 100dvh;
    }
}