.role-container {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--dark-input);
    border-radius: 8px;
    padding: 12px 8px;
    gap: 8px;
    margin-top: 8px;
    border: 1px solid #222;
}

.role-container-row {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 8px;
    width: 100%;
}

.role-container-row:hover {
    background: var(--bright-hr);
}

.left-side-container {
    height: 100%;
}

.left-side-inner-container {
    height: 95%;
    padding: 8px 16px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
}

.left-side-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid var(--room-item);
    padding-block: 8px;
}

.left-side-header-close {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 6px;
}

.left-side-header-close:hover {
    cursor: pointer;
    border: 1px solid var(--room-item);
}

/* ******************************* */

.form-container {
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    gap: 8px;
    overflow: auto;
}

.profile-pic {
    margin: auto;
    width: fit-content;
}

.media-img {
    width: 100px;
    height: 100px;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.appButton {
    width: 100px !important;
    margin-top: 16px;
}

.assigned-roles {
    padding-top: 16;
    padding-bottom: 36;
    border-bottom: 1px solid #222;
    font-size: 13px;
}

.unassignedRoles h2,
.assigned-roles h2 {
    font-size: 18px;
}

.add-btn,
.remove-btn {
    cursor: pointer;
    display: flex;
    padding: 8px 16px;
    width: fit-content;
    font-size: 11px;
    border-radius: 8px;
    gap: 4px;
    align-items: center;
    justify-content: center;
}

.remove-btn {
    background-color: var(--red);
}

.add-btn {
    background-color: var(--blue-primary);
}

.add-btn svg path {
    stroke: var(--white);
}

.redContainer {
    background-color: var(--red);
    opacity: 0.7;
    padding-block: 12px;
}

.role-member-img {
    border-radius: 13px;
    width: 60px;
    height: 60px;
}

.role-member-img-wrap {
    display: flex;
    padding-top: 24px;
    padding-bottom: 12px;
    gap: 8px;
}

.main-info-text {
    margin: 0px;
    font-size: 13px;
    line-height: 20px;
}

@media screen and (max-width: 480px) {
    .left-side-inner-container {
        padding: 8px;
    }

    .role-member-img {
        width: 60px;
        height: 60px;
    }

    .role-container-row {
        padding: 8px;
    }
}