.main-container {
    position: absolute;
    right: 50px;
    top: 50px;
    z-index: 100;
    border-radius: 8px;
    background-color: var(--darkNavy-to-lightNavy);
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--dark-blue-3);
    padding-left: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.image-icon {
    width: 24px;
    height: 24px;
}

.name-header {
    font-size: 16px;
    margin-left: 8px;
}

.strong-hr {
    background-color: var(--bright-hr);
    margin: 0;
    height: 1px;
}

.item-details {
    display: flex;
    justify-content: space-between;
    align-items: top;
}

.item-title {
    font-size: 14px;
    margin: 0;
}

.item-date {
    font-size: 10px;
    color: var(--text-4);
    margin: 0;
}

.item-content {
    display: flex;
    margin-top: 8px;
}

.thread-count {
    margin-left: 10px;
    color: var(--text-4);
    font-size: 12px;
    margin-inline-start: auto;
    background: var(--darkNavy-to-lightNavy);
    width: fit-content;
    box-sizing: border-box;
    margin-right: 12px;
    height: 20px;
    border-radius: 4px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    font-size: 12px;
}

.item-message {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    font-size: 12px;
    color: var(--text-4);
}

.list-item {
    padding: 12px;
    width: 350px;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: var(--dark-blue-3);
    cursor: pointer;
    box-sizing: border-box;
}

.list-item:last-of-type {
    margin-bottom: 0;
}

.list-item:hover {
    background-color: var(--main-card-blue-head);
}

.list-container {
    overflow-y: auto;
    height: 400px;
    padding: 12px;
    box-sizing: border-box;
}

.img {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width: 480px) {
    .main-container {
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        height: 100dvh;
        width: 100vw;
        width: 100dvw;
        box-sizing: border-box;
    }

    .close {
        margin-inline-start: auto;
        width: 20px;
        height: 20px;

    }

    .list-container {
        height: calc(100dvh - 92px);
        width: 100%;
        overflow-x: hidden;
        padding: 12px;
        box-sizing: border-box;
    }

    .list-item {
        width: 100%;
        box-sizing: border-box;
    }

    .header {
        padding-inline: 8px;
    }
}