.modal {
    background-color: var(--dark-blue-2);
    min-width: 350px;
    max-width: 500px;
    margin: 10% auto !important;
    overflow-x: hidden;
    z-index: 99;
}

.list {
    display: flex;
    flex-direction: column;
    max-height: min(300px, 50vh);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 16px 0px;
    padding: 8px 8px 8px 4px;
    position: relative;
    height: calc(100vh - 150px) !important;
}

.list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}

.list-item img {
    border-radius: 12px;
    width: 32px;
    height: 32px;
}

.modal h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.btn {
    margin-inline-start: auto;
    min-width: 48px;
    width: fit-content;
    min-height: 32px;
    height: fit-content;
    padding: 8px;
    font-size: 12px;
}

.modal h3 {
    color: var(--text-2);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}

.generate-link {
    padding: 8px 16px;
    color: var(--primary-blue);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background-color: var(--left-sidebar);
}

.generate-link .copy {
    margin-inline-start: auto;
    width: fit-content;
    height: fit-content;
}

.tab-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}

.tab {
    width: 100%;
    text-align: center;
    padding: 8px;
    border-bottom: 2px solid var(--dark-blue-3);
    cursor: pointer;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.tab:hover {
    background-color: var(--reaction-on-hover);
}

.tab:active {
    background-color: var(--reaction-on-hover);
}

.tab.selected {
    border-bottom: 2px solid var(--blue-primary);
}

.count {
    background-color: var(--blue-primary);
    padding: 1px 4px;
    border-radius: 4px;
    font-size: 12px;
}

.added-success-container {
    position: absolute;
    padding: 8px 12px;
    background: green;
    text-align: center;
    border-radius: 0px 0px 12px 12px;
    left: 0px;
    top: 0px;
    width: calc(100% - 24px);
    min-height: 16px;
    font-size: 12px;
}

@media screen and (max-width: 480px) {
    .modal {
        margin: 0 !important;
        min-width: unset;
        max-width: unset;
        width: 100dvw;
        height: 100dvh;
    }

    .list {
        height: calc(100dvh - 200px) !important;
        max-height: unset;
        padding: 0;
    }
}