.date-break {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-left: -16px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.hr {
  width: 100%;
}
