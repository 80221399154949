.section {
    flex: 1 1 100%;
    position: relative;
    background-color: #0C0922;
    height: calc(100% - 8px);
    box-sizing: border-box;
    overflow: hidden;
}

.grid {
    display: grid;
    grid-template-columns: 240px 350px auto;
    height: -webkit-fill-available;
    padding-bottom: 20px;
    overflow: hidden;
}

@media screen and (max-width: 480px) {
    .section {
        height: calc(100vh - 69px);
    }
}