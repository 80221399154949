.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
}

.header {
    padding: 16px;
    flex-direction: column;
}

.sub-heading {
    color: var(--text-heading);
    font-size: 14px;
    padding: 8px 0;
    display: flex;
}

.section-select {
    height: 52;
    margin-top: 8px;
}

.error-message {
    color: var(--red);
    margin-top: 10px;
    margin-left: 5px;
}

.error-input {
    color: var(--red);
    margin-left: 5px;
}

.form-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 24px;
    padding-left: 16px;
    position: relative;
    z-index: 6;
}

.channel-select-wrap {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 30%;
}

.channel-id-wrap {
    width: 300px;
    padding: 0;
}

.btn-wrap {
    padding-left: 15px;
    width: 200px;
    margin-top: 20px;
}

@media screen and (max-width: 480px) {
    .container {
        gap: 12px;
        height: fit-content;
    }

    .header {
        padding: 8px 0;
    }

    .form-wrap {
        padding-left: 0;
        flex-direction: column;
        gap: 12px;
    }

    .channel-select-wrap {
        max-width: 100%;
        width: 100%;
    }

    .channel-id-wrap {
        width: 100%;
    }

    .error-message,
    .error-input {
        font-size: 8px;
    }

    .btn-wrap {
        padding-left: 0;
        width: 100%;
    }

    .btn {
        width: 100%;
    }
}