.server-details-container {
    width: 22%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 8px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: calc(var(--height) - 16px);
}

.server-details-container::-webkit-scrollbar {
    display: none;
}


@media screen and (max-width: 480px) {
    .server-details-container {
        height: calc(var(--height) - 70px);
        width: 100%;
    }
}