.app-markdown {
}

.app-markdown table {
    border-collapse: collapse;
}

.app-markdown th,
.app-markdown td {
    padding: 6px 13px;
    border: 1px solid var(--white);
}

.app-markdown code {
    padding: 8px;
    background-color: var(--chat-background);
    border-radius: 4px;
    flex: 1;
    width: 100%;
    display: flex;
    /* line-height: calc(9px + 16px); */
    /* height: 100px; */
}

.app-markdown ol {
    list-style-type: decimal;
    color: var(--white);
    padding-inline-start: 1rem;
}
.app-markdown ol li {
    list-style-type: decimal;
}

.app-markdown blockquote {
    margin: 0 0 20px; /* Adjust the margin as needed */
    padding: 10px;
    border-left: 3px solid var(--bright-hr); /* Adjust border color and size as needed */
    color: var(--white); /* Adjust text color as needed */
    font-style: italic; /* Italicize the text */
}

/* Style the citation or source inside the blockquote */
.app-markdown blockquote cite {
    display: block;
    margin-top: 10px; /* Adjust the margin as needed */
    font-size: 0.9em; /* Adjust the font size as needed */
    color: var(--white); /* Adjust text color as needed */
}

.app-markdown ul {
    list-style-type: disc; /* Use disc, circle, or square for list item bullets */
    margin: 0 0 20px; /* Adjust the margin as needed */
    padding-inline-start: 1rem;
}

.app-markdown ul li {
    margin-bottom: 8px; /* Adjust the margin between list items as needed */
    list-style-type: disc;
}

/* Nested list styling */
.app-markdown ul ul {
    list-style-type: circle !important; /* Adjust the bullet style for nested lists */
    margin-top: 8px; /* Adjust the margin between nested lists */
}

.app-markdown ul ul li {
    margin-bottom: 4px; /* Adjust the margin between nested list items */
    list-style-type: circle !important;
}
