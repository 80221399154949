.left-side-container {
    /* springProps */
    height: 100%;
}

.left-side-inner-container {
    height: 100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
}

.left-side-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid var(--room-item);
}

.left-side-header-close {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    padding: 10px;
    border-radius: 6px;
}

.left-side-header-close:hover {
    cursor: pointer;
    border: 1px solid var(--room-item);
}

/* ******************************* */

.form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    padding-top: 16px;
    height: 85%;
    padding-right: 8px;
}

.input-area {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: stretch;
    justify-content: space-between;
    padding: 16px 0px;
    width: 100%;
    padding: 0;
}

.profile-pic {
    margin: auto;
    width: fit-content;
    margin-bottom: 16px;
}

.media-img {
    width: 50px;
    height: 50px;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.appButton {
    width: 100px !important;
    margin-top: auto;
    margin-bottom: 36px;
}

@media screen and (max-width: 480px) {
    .left-side-header h2 {
        font-size: 18px;
    }

    .profile-pic {
        margin-inline-start: 0;
    }

    .input-area {
        align-items: stretch;
        justify-content: flex-start;
    }

    .media-img {
        width: 40px;
        height: 40px;
    }
}