.d-flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    user-select: none;
    padding: 16px 6px;
}

.relative {
    position: relative;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    margin: auto;
}

.badge {
    background-color: var(--red);
    top: -4px;
    right: -4px;
    color: var(--keep-white);
    font-size: 9px;
    padding: 0 2px;
    min-width: 16px;
    max-width: 22px;
    width: fit-content;
    border-radius: 8px;
}

.notify-card {
    width: calc(100% - 16px) !important;
    background-color: rgba(100, 100, 100, 0.1);
}

.d-flex p {
    font-size: 10px;
    margin: 0;
}

.dummy-search {
    padding: 4px;
    padding-inline-start: 16px;
    border-radius: 8px;
    font-size: 14px;
    height: 32px;
    position: relative;
    background-color: var(--search-bar);
    opacity: 0.3;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 2px solid rgb(0, 0, 0, 0.3);
}

.search-icon {
    position: relative;
    filter: brightness(99);
}

p.mt-5 {
    margin-top: 5px;
}

.main-note path,
.note path:nth-of-type(2) {
    fill: var(--blue-icon);
}

@media screen and (max-width:480px) {
    .d-flex {
        padding: 8px;
    }

    .notify-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }

    .dummy-search {
        flex: 1;
    }

    .d-flex {
        justify-content: space-between;
        gap: 1rem;
    }
}