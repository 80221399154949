.list-item {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.list-item:last-of-type {
    opacity: 0.4;
}

.list-item>input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
}

.list-item>input:checked~.check {
    background-color: var(--blue-primary);
    border-color: transparent;
}

.paragraphs {
    max-width: 300px;

}
.paragraphs p { 
    margin-top: 0;
}
.check {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-start: auto;
    border: 1px solid var(--navy-2);
    transition: all .3s ease;
}

.check>img {}