body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: var(--white);
}

ul {
    padding-inline-start: 0;
    margin-bottom: 0;
    margin-top: 0;
}

li {
    list-style-type: none;
}

button:focus-visible,
input:focus-visible,
textarea:focus-visible {
    outline: none;
    border: none;
}

a {
    text-decoration: none;
}

[contenteditable='true'] {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    /* background: red; */
}

[contenteditable='false'] {
    -moz-user-modify: read-only;
    -webkit-user-modify: read-only;
    /* background: blue; */
}