.home-container {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 96px 1fr;
    width: 100vw;
    overflow: hidden;
}

.server-padding-container {
    width: -webkit-fill-available;
    /* height: var(--height); */
    overflow: hidden;
    height: var(--height);
    margin: 8px;
    display: inline-flex;
    gap: 8px;
}

.center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.notification-container {
    position: absolute;
    right: 50px;
    top: 50px;
    z-index: 100;
    width: 450px;
    height: 70vh;
}

.resizable-parent {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    display: flex;
}

.connecting {
    background-color: orange
}

.connected {
    background-color: green;
}


@media screen and (max-width: 480px) {
    .home-container {
        grid-template-columns: 64px 1fr;
        grid-template-columns: 1fr 64px;
    }

    .server-padding-container {
        height: calc(100dvh - 72px);
        display: block;
        box-sizing: border-box;
    }
}